import { useState } from "react";

import * as Sentry from "@sentry/browser";
import { Button } from "@stacklet/ui";
import { useMutation } from "react-relay";

import { DeleteConfirmationDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts/AlertContext";
import { RemoveAccountGroupMappingsMutation } from "app/mutations";

import type { RemoveAccountGroupMappingsMutation as RemoveAccountGroupMappingsMutationType } from "app/mutations/__generated__/RemoveAccountGroupMappingsMutation.graphql";

interface Props {
  mappingIds: string[];
}

export function RemoveAccountFromGroups({ mappingIds }: Props) {
  const { alertDispatch } = useAlertContext();

  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState<boolean>(false);
  const [mappingToBeRemoved, setMappingToBeRemoved] = useState<string[]>([]);

  const openDeleteConfirmDialog = (value: boolean) => {
    setIsDeleteConfirmDialogOpen(value);
  };

  const confirmDelete = () => {
    removeAccountFromAccountGroups();
  };

  const removeAccountFromAccountGroups = () => {
    const ids = mappingToBeRemoved;
    setMappingToBeRemoved([]);
    setIsDeleteConfirmDialogOpen(false);

    commit({
      variables: {
        input: {
          ids,
        },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          alertDispatch({
            message: `Failed to remove account from select account group(s): ${errors[0]?.message}`,
            severity: "error",
          });
        } else {
          alertDispatch({
            message: "successfully removed account from account group",
            severity: "success",
          });
          setIsDeleteConfirmDialogOpen(false);
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };

  const [commit] = useMutation<RemoveAccountGroupMappingsMutationType>(
    RemoveAccountGroupMappingsMutation,
  );

  return (
    <>
      <Button
        aria-label="remove account from group"
        buttonType="destructive"
        onClick={() => {
          setIsDeleteConfirmDialogOpen(true);
          setMappingToBeRemoved(mappingIds);
        }}
      >
        Remove from group
      </Button>

      <DeleteConfirmationDialog
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDeleteConfirmDialog}
        title="Account from Account Group"
      />
    </>
  );
}
