import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { COLORS } from "@stacklet/ui";

interface Props {
  percentage: number;
  title: string;
}

interface CircleProps {
  percentage: number;
  color?: string;
}

const TOP_LIMIT = 96;
const LOW_LIMIT = 86;

const Circle = ({ color, percentage }: CircleProps) => {
  const r = 40;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;

  return (
    <circle
      cx={100}
      cy={100}
      fill="transparent"
      r={r}
      stroke={strokePct !== circ ? color : ""}
      strokeDasharray={circ}
      strokeDashoffset={strokePct}
      strokeWidth={"0.8rem"}
    ></circle>
  );
};

export default function RadialProgressChart({
  percentage,
  title,
}: Props): JSX.Element {
  let color;

  if (percentage >= TOP_LIMIT) {
    color = COLORS.green.D30;
  } else if (percentage < LOW_LIMIT) {
    color = COLORS.red.D20;
  } else {
    color = COLORS.yellow.D40;
  }

  return (
    <svg height={205} width={205}>
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle color={COLORS.navy.L90} percentage={100} />
        {percentage > 100 ? (
          <Circle color={color} percentage={100} />
        ) : (
          <Circle color={color} percentage={percentage} />
        )}
      </g>
      <text
        dominantBaseline="central"
        fontSize={18}
        textAnchor="middle"
        x="50%"
        y="50%"
      >
        {percentage?.toFixed(0)}%
      </text>
      <foreignObject
        height="200"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        width="200"
      >
        <Box
          sx={{
            mt: "160px",
            textAlign: "center",
          }}
        >
          <Typography color={COLORS.navy.L30} variant="small">
            {title}
          </Typography>
        </Box>
      </foreignObject>
    </svg>
  );
}
