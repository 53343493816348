import React from "react";

interface IHelpContext {
  collapsed: boolean;
  expand: () => void;
  collapse: () => void;
  toggle: () => void;
  pageNames: string[];
  setPageNames: (pageNames: string[]) => void;
}

export const HelpContext = React.createContext<IHelpContext>({
  collapsed: false,
  expand: () => {
    // do nothing
  },
  collapse: () => {
    // do nothing
  },
  toggle: () => {
    // do nothing
  },
  pageNames: [],
  setPageNames: (pages: string[]) => {
    // do nothing
    pages;
  },
});
