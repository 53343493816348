import { useState } from "react";

import * as Sentry from "@sentry/browser";
import { Button } from "@stacklet/ui";
import { useMutation } from "react-relay";

import { DeleteConfirmationDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts/AlertContext";
import { RemoveAccountMutation } from "app/mutations";

import type {
  CloudProvider,
  RemoveAccountMutation as RemoveAccountMutationType,
} from "app/mutations/__generated__/RemoveAccountMutation.graphql";

interface Props {
  accountKey: string;
  accountProvider: CloudProvider;
}

const RemoveAccount = ({ accountKey, accountProvider }: Props) => {
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState<boolean>(false);

  const openDeleteConfirmDialog = (value: boolean) => {
    setIsDeleteConfirmDialogOpen(value);
  };

  const confirmDelete = () => {
    deleteAccount(accountKey, accountProvider);
  };
  const { alertDispatch } = useAlertContext();

  const onDeleteFailure = (errorMessage: string) => {
    alertDispatch({
      message: `failed to remove account: ${errorMessage}`,
      severity: "error",
    });
  };

  const deleteAccount = (key: string, provider: CloudProvider) => {
    removeAccount({
      variables: {
        key,
        provider,
      },
      onCompleted(response, errors) {
        if (errors) {
          errors.forEach((error) => {
            onDeleteFailure(error.message);
          });
        } else {
          alertDispatch({
            message: "successfully removed account",
            severity: "success",
          });
          setIsDeleteConfirmDialogOpen(false);
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };

  const [removeAccount] = useMutation<RemoveAccountMutationType>(
    RemoveAccountMutation,
  );

  return (
    <>
      <Button
        aria-label="remove account"
        buttonType="destructive"
        onClick={() => setIsDeleteConfirmDialogOpen(true)}
      >
        Remove
      </Button>

      <DeleteConfirmationDialog
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDeleteConfirmDialog}
        redirectPath="/admin/accounts"
        title="Account"
      />
    </>
  );
};
export default RemoveAccount;
