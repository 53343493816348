import { Helmet } from "react-helmet-async";

import { Page } from "app/components/layout/Page";

import OverviewBreadcrumbs from "./OverviewBreadcrumbs";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function OverviewPage({ title, children }: Props) {
  return (
    <Page>
      <>
        <Helmet>
          <title>Overview | {title}</title>
        </Helmet>
        <OverviewBreadcrumbs />
        {children}
      </>
    </Page>
  );
}
