import { useState } from "react";

import { useCubeQuery } from "@cubejs-client/react";

import { BindingStatusEnum } from "./types";

import type { BindingProgress } from "./types";
import type { Query } from "@cubejs-client/core";

const STORAGE_PREFIX = "binding-started-";

export function useBindingStatus() {
  const [lastUpdate, setLastUpdate] = useState("");

  const bindingRunQuery = {
    order: [["BindingRun.start_time", "desc"]],
    dimensions: [
      "BindingRun.binding_uuid",
      "BindingRun.elapsed_time",
      "BindingRun.error_count",
      "BindingRun.expected_executions",
      "BindingRun.last_update",
      "BindingRun.saved_executions",
      "BindingRun.start_time",
    ],
    segments: ["BindingRun.running"],
  } as Query;

  const { resultSet, isLoading } = useCubeQuery(bindingRunQuery, {
    subscribe: true,
  });

  function getBindingProgress(bindingUuid = ""): BindingProgress | null {
    if (isLoading || !resultSet) {
      return null;
    }

    const rawData = resultSet.rawData().filter((row) => {
      return row["BindingRun.binding_uuid"] === bindingUuid;
    });

    /* We don't have any binding run query results, let's check our client-side
     * flag to see if the user just clicked the button to run the binding.
     */
    if (rawData.length === 0) {
      const bindingStartTime = window.localStorage.getItem(
        STORAGE_PREFIX + bindingUuid,
      );
      if (bindingStartTime) {
        const elapsed = new Date().getTime() - Number(bindingStartTime);
        if (elapsed > 1000 * 60 * 10) {
          /* We have a client-side "binding started" flag, but it's older than
           * 10 minutes - remove and disregard.
           */
          window.localStorage.removeItem(STORAGE_PREFIX + bindingUuid);
        } else {
          return {
            bindingUuid,
            status: BindingStatusEnum.STARTING,
          } as BindingProgress;
        }
      }
      return {
        bindingUuid,
        status: BindingStatusEnum.NOT_RUNNING,
      } as BindingProgress;
    } else {
      window.localStorage.removeItem(STORAGE_PREFIX + bindingUuid);
    }

    const data = rawData[0];
    const expectedExecutions = data["BindingRun.expected_executions"] || 0;
    const savedExecutions = data["BindingRun.saved_executions"] || 0;
    const errorCount = data["BindingRun.error_count"] || 0;
    const progressPercent =
      expectedExecutions === 0 || savedExecutions === 0
        ? 0
        : Math.fround((savedExecutions / expectedExecutions) * 100);
    const errorPercent =
      expectedExecutions === 0 || errorCount === 0
        ? 0
        : Math.fround((errorCount / expectedExecutions) * 100);

    return {
      bindingUuid,
      elapsedTime: data["BindingRun.elapsed_time"],
      errorCount: data["BindingRun.error_count"],
      expectedExecutions,
      lastUpdate: data["BindingRun.last_update"],
      savedExecutions,
      startTime: data["BindingRun.start_time"],
      progressPercent,
      errorPercent,
      status: BindingStatusEnum.RUNNING,
    } as BindingProgress;
  }

  function setBindingStarted(bindingUuid: string | null | undefined) {
    if (!bindingUuid) {
      return;
    }
    const timeStamp = new Date().getTime().toString() || lastUpdate;
    setLastUpdate(timeStamp);
    window.localStorage.setItem(STORAGE_PREFIX + bindingUuid, timeStamp);
  }

  return { getBindingProgress, setBindingStarted };
}
