/**
 * @generated SignedSource<<bd156537ad01240abd9565ebf28547ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type AccountNameAutocompleteRefetchQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
};
export type AccountNameAutocompleteRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountNameAutocomplete_accounts">;
};
export type AccountNameAutocompleteRefetchQuery = {
  response: AccountNameAutocompleteRefetchQuery$data;
  variables: AccountNameAutocompleteRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountNameAutocompleteRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccountNameAutocomplete_accounts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountNameAutocompleteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filterElement",
            "variableName": "filterElement"
          },
          (v1/*: any*/)
        ],
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e686f9e6e7ebae8e0a5c420d73670a1",
    "id": null,
    "metadata": {},
    "name": "AccountNameAutocompleteRefetchQuery",
    "operationKind": "query",
    "text": "query AccountNameAutocompleteRefetchQuery(\n  $filterElement: FilterElementInput\n  $first: Int = 10\n) {\n  ...AccountNameAutocomplete_accounts_3ASum4\n}\n\nfragment AccountNameAutocomplete_accounts_3ASum4 on Query {\n  accounts(filterElement: $filterElement, first: $first) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0343477176ead9cc802f5f96feb131b4";

export default node;
