import type React from "react";
import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

interface Props {
  children: React.ReactNode;
}

export default function TableActions({ children }: Props): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const menuOpen = Boolean(menuAnchorEl);

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="actions"
        data-testid="table-action-menu"
        onClick={(e) => {
          e.stopPropagation();
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        onClick={handleClose}
        onClose={handleClose}
        open={menuOpen}
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
}
