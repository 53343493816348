/**
 * @generated SignedSource<<96f531e2f0ea99329fa8ffc1c4341a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateBindingInput = {
  autoDeploy?: boolean | null | undefined;
  description?: string | null | undefined;
  executionConfig?: ExecutionConfigInput | null | undefined;
  name?: string | null | undefined;
  schedule?: string | null | undefined;
  uuid: string;
  variables?: string | null | undefined;
};
export type ExecutionConfigInput = {
  dryRun?: ExecutionDryRunInput | null | undefined;
  resourceLimits?: ExecutionResourceLimitsInput | null | undefined;
  securityContext?: ExecutionSecurityContextInput | null | undefined;
  variables?: string | null | undefined;
};
export type ExecutionSecurityContextInput = {
  default?: string | null | undefined;
};
export type ExecutionDryRunInput = {
  default?: boolean | null | undefined;
};
export type ExecutionResourceLimitsInput = {
  default?: ResourceLimitInput | null | undefined;
  policyOverrides?: ReadonlyArray<PolicyResourceLimitInput> | null | undefined;
};
export type ResourceLimitInput = {
  maxCount?: number | null | undefined;
  maxPercentage?: number | null | undefined;
  requiresBoth?: boolean | null | undefined;
};
export type PolicyResourceLimitInput = {
  limit: ResourceLimitInput;
  policyName: string;
};
export type UpdateBindingMutation$variables = {
  input: UpdateBindingInput;
};
export type UpdateBindingMutation$data = {
  readonly updateBinding: {
    readonly binding: {
      readonly autoDeploy: boolean;
      readonly description: string | null | undefined;
      readonly executionConfig: {
        readonly dryRun: {
          readonly default: boolean;
        } | null | undefined;
        readonly resourceLimits: {
          readonly default: {
            readonly maxCount: number | null | undefined;
            readonly maxPercentage: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly securityContext: {
          readonly default: string | null | undefined;
        } | null | undefined;
        readonly variables: string | null | undefined;
      };
      readonly id: string;
      readonly name: string;
      readonly schedule: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateBindingMutation = {
  response: UpdateBindingMutation$data;
  variables: UpdateBindingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "default",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BindingPayload",
    "kind": "LinkedField",
    "name": "updateBinding",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Binding",
        "kind": "LinkedField",
        "name": "binding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoDeploy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExecutionConfig",
            "kind": "LinkedField",
            "name": "executionConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExecutionDryRun",
                "kind": "LinkedField",
                "name": "dryRun",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExecutionResourceLimits",
                "kind": "LinkedField",
                "name": "resourceLimits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceLimit",
                    "kind": "LinkedField",
                    "name": "default",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxPercentage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExecutionSecurityContext",
                "kind": "LinkedField",
                "name": "securityContext",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variables",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBindingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBindingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7ca2c9771aa14d33efbea1fccb315762",
    "id": null,
    "metadata": {},
    "name": "UpdateBindingMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBindingMutation(\n  $input: UpdateBindingInput!\n) {\n  updateBinding(input: $input) {\n    binding {\n      id\n      uuid\n      name\n      description\n      schedule\n      autoDeploy\n      executionConfig {\n        dryRun {\n          default\n        }\n        resourceLimits {\n          default {\n            maxCount\n            maxPercentage\n          }\n        }\n        securityContext {\n          default\n        }\n        variables\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab49945e8cc553e383d62b90fda3041a";

export default node;
