/**
 * @generated SignedSource<<97b0c90882b02e662af9ec0b222c014b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailConfigurationInput = {
  fromEmail: string;
  name: string;
  scope: string;
  sesRegion?: string | null | undefined;
  smtp?: SmtpConfigurationInput | null | undefined;
};
export type SmtpConfigurationInput = {
  password?: string | null | undefined;
  port?: string | null | undefined;
  server: string;
  ssl?: boolean | null | undefined;
  username?: string | null | undefined;
};
export type AddEmailProfileMutation$variables = {
  connections: ReadonlyArray<string>;
  input: EmailConfigurationInput;
};
export type AddEmailProfileMutation$data = {
  readonly addEmailProfile: {
    readonly configuration: {
      readonly id: string;
      readonly profile: string;
      readonly record: {
        readonly fromEmail?: string;
        readonly sesRegion?: string | null | undefined;
        readonly smtp?: {
          readonly port: string | null | undefined;
          readonly server: string;
          readonly ssl: boolean | null | undefined;
          readonly username: string | null | undefined;
        } | null | undefined;
      };
      readonly scope: string;
    };
  } | null | undefined;
};
export type AddEmailProfileMutation = {
  response: AddEmailProfileMutation$data;
  variables: AddEmailProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fromEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SmtpConfiguration",
      "kind": "LinkedField",
      "name": "smtp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "server",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "port",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ssl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sesRegion",
      "storageKey": null
    }
  ],
  "type": "EmailConfiguration",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddEmailProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addEmailProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddEmailProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addEmailProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "configuration",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ConfigurationEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59ab721ce19182a7befd8517b677b6d3",
    "id": null,
    "metadata": {},
    "name": "AddEmailProfileMutation",
    "operationKind": "mutation",
    "text": "mutation AddEmailProfileMutation(\n  $input: EmailConfigurationInput!\n) {\n  addEmailProfile(input: $input) {\n    configuration {\n      id\n      scope\n      profile\n      record {\n        __typename\n        ... on EmailConfiguration {\n          fromEmail\n          smtp {\n            server\n            port\n            ssl\n            username\n          }\n          sesRegion\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d45d1bb964962d7dbeed98116d483c97";

export default node;
