import { useContext, useEffect, useState } from "react";

import { ApolloSandbox } from "@apollo/sandbox/react";
import Box from "@mui/system/Box";
import { Auth } from "aws-amplify";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { SideBarContext } from "app/contexts";

import type { SandboxQuery } from "./__generated__/SandboxQuery.graphql";

function resizeSandbox() {
  const iframe = document.querySelector("iframe");
  if (iframe) {
    iframe.style.minHeight = "100vh";
  }
}

export function Sandbox() {
  const [token, setToken] = useState("");
  const { collapsed: isSideBarCollapsed } = useContext(SideBarContext);

  const data = useLazyLoadQuery<SandboxQuery>(
    graphql`
      query SandboxQuery {
        Deployment {
          gqlUri
        }
      }
    `,
    {},
  );

  useEffect(() => {
    async function getToken() {
      const session = await Auth.currentSession();
      setToken(session.getAccessToken().getJwtToken());
    }
    getToken();
  }, []);

  if (!data.Deployment?.gqlUri || !token) {
    return null;
  }

  return (
    <Box
      sx={
        isSideBarCollapsed
          ? { ml: -8, mr: -7 }
          : { ml: -3, mr: -3, pb: 0, mb: 0 }
      }
    >
      <ApolloSandbox
        endpointIsEditable={false}
        handleRequest={(endpointUrl, options) => {
          resizeSandbox();

          return fetch(endpointUrl, {
            ...options,
            headers: {
              ...options.headers,
              authorization: token,
            },
          });
        }}
        initialEndpoint={data.Deployment.gqlUri}
        initialState={{
          headers: {
            authorization: token,
          },
        }}
      />
    </Box>
  );
}
