import { useUserContext } from "app/contexts/UserContext";
import { isEqual } from "app/utils/permissions";

import type { Permission } from "app/contexts/UserContext";

export function useHasRequiredPermission(): (
  requiredPermission: Permission,
) => boolean {
  const permissions = useUserContext();

  return (requiredPermission: Permission) =>
    permissions.some((permission) => isEqual(permission, requiredPermission));
}
