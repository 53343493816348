import { graphql } from "react-relay";
import { useFragment } from "react-relay";

import { Description } from "./Description";

import type { DashboardDescription_dashboard$key } from "./__generated__/DashboardDescription_dashboard.graphql";

interface Props {
  dashboard: DashboardDescription_dashboard$key;
}

export function DashboardDescription({ dashboard }: Props) {
  const data = useFragment(
    graphql`
      fragment DashboardDescription_dashboard on Dashboard {
        description
      }
    `,
    dashboard,
  );

  if (!data.description) {
    return null;
  }

  return <Description heading={false}>{data.description}</Description>;
}
