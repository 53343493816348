import { graphql } from "react-relay";

export const RemovePolicyCollectionMutation = graphql`
  mutation RemovePolicyCollectionMutation($uuid: String!) {
    removePolicyCollection(uuid: $uuid) {
      collection {
        id @deleteRecord
      }
    }
  }
`;
