import { search } from "@metrichor/jmespath";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import * as Sentry from "@sentry/browser";
import { Tooltip } from "@stacklet/ui";
import { addMinutes, format, formatDistance } from "date-fns";

import { getColumnWidthFromCharCount } from "app/components/table/columnWidthHelper";

import type { JSONValue } from "@metrichor/jmespath";
import type {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

const getValueFromPath = (params: GridValueGetterParams): JSONValue => {
  const { row, field } = params;
  // field is a jmespath expression e.g. "Location.LocationConstraint"
  return search(row, field);
};

const defaultFormatter = (params: GridValueFormatterParams) => {
  const { value } = params;
  if (!value || value == null) {
    return "--";
  }
  if (Array.isArray(value)) {
    return value.toString();
  }

  return value;
};

export const formatColumns = (columns: GridColDef[]): GridColDef[] =>
  (columns ?? []).map((column) => {
    column.valueGetter = getValueFromPath;
    column.valueFormatter = defaultFormatter;

    const DEFAULT_COLUMN_WIDTH = 150;

    if (!column.width) {
      column.width = column.headerName
        ? getColumnWidthFromCharCount(column.headerName.length)
        : DEFAULT_COLUMN_WIDTH;
    }

    column.resizable = true;

    //For rest-stage resource, if there's a value for webAclArn then return the value Yes, else return No
    if (column.field === "webAclArn") {
      column.valueFormatter = (params: GridValueFormatterParams) => {
        return params.value ? "Yes" : "No";
      };
    }

    if (column.type === "array") {
      column.renderCell = (params: GridCellParams) => {
        if (params.value) {
          try {
            const items = JSON.parse(params.value as string);
            if (items.length === 0) {
              return <span>--</span>;
            }
            return (
              <Tooltip
                placement="left"
                title={
                  <div>
                    <List>
                      {(items ?? []).map((item: string) => (
                        <ListItem key={item}>{item}</ListItem>
                      ))}
                    </List>
                  </div>
                }
              >
                <span>{items.join(", ")}</span>
              </Tooltip>
            );
          } catch {
            Sentry.captureMessage(`Unable to deserialise array for ${column}`);
          }
        }
        return <span>--</span>;
      };
    }

    if (column.type === "time") {
      column.type = "string";
      column.sortable = true;
      column.sortComparator = (v1, v2) =>
        new Date(v1 as string) > new Date(v2 as string) ? 1 : -1;
      column.renderCell = (params) => {
        const value = params.value as string;
        try {
          const tooltipDate = format(
            addMinutes(new Date(value), new Date(value).getTimezoneOffset()),
            "PPp",
          );
          return (
            <Tooltip title={tooltipDate}>
              <span>
                {formatDistance(new Date(value), new Date(), {
                  addSuffix: true,
                })}
              </span>
            </Tooltip>
          );
        } catch {
          return <span>{value}</span>;
        }
      };
    }
    return column;
  });
