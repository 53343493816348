/**
 * @generated SignedSource<<facfd224bc51a1ad01a3b8dc50b1c3d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoPolicies_repositories$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename: "RepositoryEdge";
  }>;
  readonly " $fragmentType": "NoPolicies_repositories";
};
export type NoPolicies_repositories$key = {
  readonly " $data"?: NoPolicies_repositories$data;
  readonly " $fragmentSpreads": FragmentRefs<"NoPolicies_repositories">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoPolicies_repositories",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RepositoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepositoryConnection",
  "abstractKey": null
};

(node as any).hash = "da3c85138629fbdd93819b7916aebad1";

export default node;
