import { Paper } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useFragment } from "react-relay";

import type { ExecutionLog_log$key } from "./__generated__/ExecutionLog_log.graphql";

interface Props {
  execution: ExecutionLog_log$key;
}

export default function ResourceDetailsHeader({
  execution,
}: Props): JSX.Element {
  const data = useFragment(
    graphql`
      fragment ExecutionLog_log on PolicyExecution {
        log
      }
    `,
    execution,
  );

  return (
    <>
      <Paper
        data-testid="execution-log--view"
        sx={{
          width: "100%",
          mt: 2,
          p: 2,
          whiteSpace: "pre-wrap",
          fontFamily: "monospace",
        }}
      >
        <div>{data.log}</div>
      </Paper>
    </>
  );
}
