import { graphql } from "react-relay";

export const ExportConnectionMutation = graphql`
  mutation ExportConnectionMutation($input: ExportConnectionInput!) {
    exportConnection(input: $input) {
      export {
        id
      }
    }
  }
`;
