import Skeleton from "@mui/material/Skeleton";

interface Props {
  topMargin?: number;
  height: string;
  width?: string;
}

export default function RectangleSkeleton({
  topMargin,
  height,
  width = "100%",
}: Props) {
  return (
    <Skeleton
      animation="wave"
      height={height}
      role="progressbar"
      sx={{ mt: topMargin || "", zIndex: 3 }}
      variant="rectangular"
      width={width}
    />
  );
}
