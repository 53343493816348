import { endOfMonth, format, subMonths } from "date-fns";

export const EOM_DATE_STR = format(
  endOfMonth(subMonths(new Date(), 1)),
  "yyyy-MM-dd",
);

export function todayOrYesterday(date: Date = new Date()): string {
  const utcHours = date.getUTCHours();
  if (utcHours >= 0 && utcHours < 5) {
    return "Yesterday";
  }
  return "Today";
}
