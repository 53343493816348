/**
 * @generated SignedSource<<7a9735667db4498979ec97053e5c5a06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicyChangelog_history$data = {
  readonly history: ReadonlyArray<{
    readonly action: string;
    readonly commit: {
      readonly author: string;
      readonly authorEmail: string;
      readonly date: string;
      readonly hash: string;
      readonly msg: string;
    };
    readonly id: string;
  }>;
  readonly " $fragmentType": "PolicyChangelog_history";
};
export type PolicyChangelog_history$key = {
  readonly " $data"?: PolicyChangelog_history$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyChangelog_history">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PolicyChangelog_history",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Policy",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RepoCommit",
          "kind": "LinkedField",
          "name": "commit",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hash",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "msg",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "author",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "authorEmail",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "01cc053df2b6fbf9a5286b7800a1aba7";

export default node;
