/**
 * @generated SignedSource<<d2eb60e16c2f7e5e89c773cbe7942f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type UpdateAccountGroupInput = {
  description?: string | null | undefined;
  dynamicFilter?: string | null | undefined;
  name?: string | null | undefined;
  priority?: number | null | undefined;
  regions?: ReadonlyArray<string> | null | undefined;
  shortName?: string | null | undefined;
  uuid: string;
  variables?: string | null | undefined;
};
export type UpdateAccountGroupMutation$variables = {
  input: UpdateAccountGroupInput;
};
export type UpdateAccountGroupMutation$data = {
  readonly updateAccountGroup: {
    readonly group: {
      readonly description: string | null | undefined;
      readonly dynamicFilter: string | null | undefined;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly dynamic: boolean;
        readonly key: string;
        readonly name: string | null | undefined;
        readonly provider: CloudProvider;
        readonly uuid: string;
      }> | null | undefined;
      readonly name: string;
      readonly regions: ReadonlyArray<string> | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateAccountGroupMutation = {
  response: UpdateAccountGroupMutation$data;
  variables: UpdateAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountGroupPayload",
    "kind": "LinkedField",
    "name": "updateAccountGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dynamicFilter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regions",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroupItem",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dynamic",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountGroupMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountGroupMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a4d963c14cb9224ab835c1d654ed495a",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountGroupMutation(\n  $input: UpdateAccountGroupInput!\n) {\n  updateAccountGroup(input: $input) {\n    group {\n      id\n      name\n      description\n      dynamicFilter\n      regions\n      uuid\n      items {\n        uuid\n        key\n        provider\n        name\n        dynamic\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "16fb09834d6aa48401fc639d3e121d84";

export default node;
