/**
 * @generated SignedSource<<64d4b589d5bc3830f9541ae23320858e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveAccountGroupMappingsInput = {
  ids: ReadonlyArray<string>;
};
export type RemoveAccountGroupMappingsMutation$variables = {
  input: RemoveAccountGroupMappingsInput;
};
export type RemoveAccountGroupMappingsMutation$data = {
  readonly removeAccountGroupMappings: {
    readonly removed: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type RemoveAccountGroupMappingsMutation = {
  response: RemoveAccountGroupMappingsMutation$data;
  variables: RemoveAccountGroupMappingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAccountGroupMappingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveAccountGroupMappingsPayload",
        "kind": "LinkedField",
        "name": "removeAccountGroupMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveAccountGroupMappingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveAccountGroupMappingsPayload",
        "kind": "LinkedField",
        "name": "removeAccountGroupMappings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f57dd48f3147ecc8b5f3b9dfe84a4428",
    "id": null,
    "metadata": {},
    "name": "RemoveAccountGroupMappingsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAccountGroupMappingsMutation(\n  $input: RemoveAccountGroupMappingsInput!\n) {\n  removeAccountGroupMappings(input: $input) {\n    removed {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0b298f6e8b864af15dc8f5527172750";

export default node;
