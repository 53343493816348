/**
 * @generated SignedSource<<3706ef0289bbfe0bdea3f1bb80d1282c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountIdAutocomplete_accounts$data = {
  readonly accounts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly key: string;
      };
    }>;
  };
  readonly " $fragmentType": "AccountIdAutocomplete_accounts";
};
export type AccountIdAutocomplete_accounts$key = {
  readonly " $data"?: AccountIdAutocomplete_accounts$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountIdAutocomplete_accounts">;
};

import AccountIdAutocompleteRefetchQuery_graphql from './AccountIdAutocompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterElement"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": AccountIdAutocompleteRefetchQuery_graphql
    }
  },
  "name": "AccountIdAutocomplete_accounts",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "AccountConnection",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "dce42049224744784ee7f1404891fea8";

export default node;
