/**
 * @generated SignedSource<<acedaac4003e056a90687a3a0632047b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicySource_policy$data = {
  readonly created: string;
  readonly modified: string;
  readonly repository: {
    readonly url: string;
  } | null | undefined;
  readonly sourceYAML: string;
  readonly version: number;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyChangelog_history">;
  readonly " $fragmentType": "PolicySource_policy";
};
export type PolicySource_policy$key = {
  readonly " $data"?: PolicySource_policy$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicySource_policy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PolicySource_policy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceYAML",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Repository",
      "kind": "LinkedField",
      "name": "repository",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PolicyChangelog_history"
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "2a8b9b25b9cf6f69a2772f02157ded0e";

export default node;
