import * as Sentry from "@sentry/react";
import { lazily } from "react-lazily";
import { graphql } from "react-relay";
import { useFragment } from "react-relay";
import { Routes, Navigate, Route } from "react-router-dom";

import { AdminRoute } from "app/components/AdminRoute/AdminRoute";
import { NotFound } from "app/views";
import { Activity } from "app/views/overview/activity/Activity";
import { Sandbox } from "app/views/sandbox/Sandbox";

import type { Routes_accounts$key } from "__generated__/Routes_accounts.graphql";

const { AdminRoutes: Admin } = lazily(
  () => import("./app/views/admin/AdminRoutes"),
);

const { DashboardRoutes: Dashboards } = lazily(
  () => import("./app/views/dashboards/DashboardRoutes"),
);

const { Notifications } = lazily(
  () => import("./app/views/notifications/Notifications"),
);

const { SettingsRoutes: Settings } = lazily(
  () => import("./app/views/settings/SettingsRoutes"),
);

const { CloudOpsRoutes: CloudOps } = lazily(
  () => import("./app/views/cloudops/CloudOpsRoutes"),
);

const { ReadonlyBindingRoutes: Bindings } = lazily(
  () => import("./app/views/read-only/bindings/ReadonlyBindingRoutes"),
);

const { AccountGroups, Onboarding, Policies, PolicyCollections, Accounts } =
  lazily(() => import("app/views"));

interface Props {
  queryRef: Routes_accounts$key;
}

export function AppRoutes({ queryRef }: Props) {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const data = useFragment(
    graphql`
      fragment Routes_accounts on Query {
        accounts {
          pageInfo {
            total
          }
        }
        whoami {
          __typename
          config {
            isOnboardingSkipped
          }
        }
      }
    `,
    queryRef,
  );

  if (
    data.accounts.pageInfo.total === 0 &&
    !data.whoami.config?.isOnboardingSkipped
  ) {
    return (
      <Routes>
        <Route element={<Navigate to="/onboarding" />} path="/*" />
        <Route element={<Onboarding />} path="onboarding/*" />
      </Routes>
    );
  }

  const overviewRerouteTarget = "/dashboards/cloud";

  return (
    <SentryRoutes>
      <Route element={<Onboarding />} path="onboarding/*" />
      <Route element={<Navigate to="/overview" />} index />
      <Route element={<Navigate to="/overview" />} path="setup/*" />
      <Route
        element={<Navigate to={overviewRerouteTarget} />}
        path="overview/*"
      />
      <Route element={<Activity />} path="activity/*" />
      <Route element={<Dashboards />} path="dashboards/*" />
      <Route element={<Policies />} path="policies/*" />
      <Route element={<CloudOps />} path="cloudops/*" />
      <Route element={<Admin />} path="admin/*" />
      <Route
        element={
          <AdminRoute>
            <Settings />
          </AdminRoute>
        }
        path="settings/*"
      />
      <Route element={<Accounts />} path="accounts/*" />
      <Route element={<Bindings />} path="bindings/*" />
      <Route element={<PolicyCollections />} path="policy-collections/*" />
      <Route element={<AccountGroups />} path="account-groups/*" />
      <Route
        element={
          <AdminRoute>
            <Notifications />
          </AdminRoute>
        }
        path="notifications/*"
      />
      <Route element={<Sandbox />} path="api" />
      <Route element={<NotFound />} path="*" />
    </SentryRoutes>
  );
}
