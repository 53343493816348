/**
 * @generated SignedSource<<b3475b1bef0d9f0d0c18aa9d3df83af3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResourceOwnerConfigurationInput = {
  default?: ReadonlyArray<string> | null | undefined;
  name: string;
  orgDomain?: string | null | undefined;
  orgDomainTag?: string | null | undefined;
  scope: string;
  tags: ReadonlyArray<string>;
};
export type AccountOwnersConfigurationInput = {
  default?: ReadonlyArray<AccountOwnersInput> | null | undefined;
  name?: string | null | undefined;
  orgDomain?: string | null | undefined;
  orgDomainTag?: string | null | undefined;
  scope: string;
  tags?: ReadonlyArray<string> | null | undefined;
};
export type AccountOwnersInput = {
  account: string;
  owners: ReadonlyArray<string>;
};
export type UpdateOwnerProfileMutation$variables = {
  accountOwnerProfileInput: AccountOwnersConfigurationInput;
  resourceOwnerProfileInput: ResourceOwnerConfigurationInput;
};
export type UpdateOwnerProfileMutation$data = {
  readonly addAccountOwnersProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly default?: ReadonlyArray<{
          readonly owners: ReadonlyArray<string>;
        }>;
        readonly tags?: ReadonlyArray<string>;
      };
    };
  } | null | undefined;
  readonly addResourceOwnerProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly default?: ReadonlyArray<string> | null | undefined;
        readonly tags?: ReadonlyArray<string>;
      };
    };
  } | null | undefined;
};
export type UpdateOwnerProfileMutation = {
  response: UpdateOwnerProfileMutation$data;
  variables: UpdateOwnerProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountOwnerProfileInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resourceOwnerProfileInput"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "resourceOwnerProfileInput"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default",
      "storageKey": null
    }
  ],
  "type": "ResourceOwnerConfiguration",
  "abstractKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "accountOwnerProfileInput"
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountOwners",
      "kind": "LinkedField",
      "name": "default",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owners",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountOwnersConfiguration",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOwnerProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addResourceOwnerProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addAccountOwnersProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateOwnerProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addResourceOwnerProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addAccountOwnersProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "227773ef0e49181bd8a859364596aae1",
    "id": null,
    "metadata": {},
    "name": "UpdateOwnerProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOwnerProfileMutation(\n  $resourceOwnerProfileInput: ResourceOwnerConfigurationInput!\n  $accountOwnerProfileInput: AccountOwnersConfigurationInput!\n) {\n  addResourceOwnerProfile(input: $resourceOwnerProfileInput) {\n    configuration {\n      profile\n      record {\n        __typename\n        ... on ResourceOwnerConfiguration {\n          tags\n          default\n        }\n      }\n      id\n    }\n  }\n  addAccountOwnersProfile(input: $accountOwnerProfileInput) {\n    configuration {\n      profile\n      record {\n        __typename\n        ... on AccountOwnersConfiguration {\n          tags\n          default {\n            owners\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68cb7a4e513464c04b9225dd2e7d6e3e";

export default node;
