import { useMemo } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Button, Paper } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import NoData from "app/components/empty-state/NoData";
import CircularSuspense from "app/components/suspense/CircularSuspense";
import { Tab } from "app/components/Tab";
import { useTabsRef } from "app/hooks";
import { DEFAULT_PAGE_SIZE } from "app/utils/consts";

import { AccountGroupsTableDetail } from "./AccountGroupsTableDetail";
import RemoveAccount from "./actions/RemoveAccount";
import RevalidateAccount from "./actions/RevalidateAccount";
import Meta from "./components/Meta";

import type {
  AccountDetailsQuery,
  CloudProvider,
} from "./__generated__/AccountDetailsQuery.graphql";

interface Props {
  showActions?: boolean;
}

const ActionButton = styled("div")(({ theme }) => ({
  "&+.actionButton": {
    marginLeft: theme.spacing(1),
  },
}));

export function AccountDetails({ showActions = false }: Props) {
  const params = useParams<"accountProvider" | "accountKey">();
  const navigate = useNavigate();
  const tabsRef = useTabsRef();
  const [searchParams] = useSearchParams({});

  const accountProvider = (params.accountProvider as CloudProvider) ?? "AWS";
  const accountKey = params.accountKey ?? "";

  const view = searchParams.get("view");
  const search = searchParams.get("search");
  const tabValue = view || "accountGroups";

  const filterElement = useMemo(
    () =>
      search
        ? {
            single: {
              name: "name",
              operator: "contains",
              value: search,
            },
          }
        : null,
    [search],
  );

  const data = useLazyLoadQuery<AccountDetailsQuery>(
    graphql`
      query AccountDetailsQuery(
        $key: String!
        $provider: CloudProvider!
        $first: Int
        $after: String
        $filterElement: FilterElementInput
      ) {
        account(key: $key, provider: $provider) {
          name
          provider
          key
          status
          status_message
          ...Meta_account
          ...AccountGroupsTableDetailFragment
            @arguments(
              first: $first
              after: $after
              filterElement: $filterElement
            )
          ...RevalidateAccount_account
        }
      }
    `,
    {
      key: accountKey,
      provider: accountProvider,
      first: DEFAULT_PAGE_SIZE,
      filterElement,
    },
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data?.account?.key ? (
        <>
          <Paper>
            <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
              <Typography variant="subtitle1">{data.account.name}</Typography>
              {showActions ? (
                <Box data-testid="action-buttons" display="flex">
                  <ActionButton className="actionButton">
                    <RevalidateAccount
                      account={data.account}
                    ></RevalidateAccount>
                  </ActionButton>
                  <ActionButton className="actionButton">
                    <Button
                      buttonType="outline-main"
                      dataTestID="account-details-edit"
                      onClick={() => navigate("edit")}
                    >
                      Edit
                    </Button>
                  </ActionButton>
                  <ActionButton className="actionButton">
                    <RemoveAccount
                      accountKey={data.account.key}
                      accountProvider={data.account.provider}
                    />
                  </ActionButton>
                </Box>
              ) : null}
            </Box>
            <Box mb={3}>
              {data.account.status === false ? (
                <Alert
                  severity="error"
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  {data.account.status_message}
                </Alert>
              ) : null}
            </Box>
            <Meta account={data.account} />
          </Paper>
          <Tabs
            action={tabsRef}
            aria-label="Account Groups tabs"
            indicatorColor="primary"
            sx={{ mt: 4 }}
            value={tabValue}
          >
            <Tab
              label="Account Groups"
              onClick={() => navigate("?view=accountGroups")}
              value="accountGroups"
            />
          </Tabs>
          <CircularSuspense>
            {!view || view === "accountGroups" ? (
              <AccountGroupsTableDetail
                account={data.account}
                showActions={showActions}
              />
            ) : null}
          </CircularSuspense>
        </>
      ) : (
        <NoData message="Account does not exist." />
      )}
    </Box>
  );
}
