interface BackgroundMatch {
  background: string;
  color: string;
}

export const backgroundColorFormatter = (md5: string): BackgroundMatch => {
  const matches: string[] = md5.match(/.{2}/g) || [];

  const [green, blue, red] = matches.map((hex) => parseInt(hex, 16));

  // Formula from https://www.w3.org/TR/AERT/#color-contrast
  const luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255;

  const color = luminance > 0.6 ? "#222" : "#fff";

  return {
    background: `rgb(${[red, green, blue]})`,
    color,
  };
};

export const initialsFormatter = (name: string, maxLength = 2): string => {
  if (name.length <= maxLength) return name;

  return name
    .split(" ")
    .map((chars) => chars[0])
    .join("")
    .substring(0, 2);
};
