import { graphql } from "react-relay";

export const ValidateAccountMutation = graphql`
  mutation ValidateAccountMutation($input: ValidateAccountInput!) {
    validateAccount(input: $input) {
      account {
        id
        name
        provider
        key
        path
        email
        securityContext
        status
        status_message
      }
    }
  }
`;
