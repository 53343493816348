import { useState } from "react";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";

import type { Support_whoami$key } from "./__generated__/Support_whoami.graphql";

const FRESHWORKS_WIDGET_SRC =
  "https://widget.freshworks.com/widgets/72000002430.js";

export type Display = "button" | "link";

interface Props {
  queryRef: Support_whoami$key;
  display: Display;
}

export function Support({ queryRef, display = "link" }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const data = useFragment(
    graphql`
      fragment Support_whoami on Query {
        whoami {
          email
          displayName
        }
      }
    `,
    queryRef,
  );

  if (!data.whoami) {
    return null;
  }

  const { email, displayName } = data.whoami;

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    closeMenu();
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function openSupportWidget() {
    window.FreshworksWidget("open");
    closeMenu();
  }

  function openHelpPortal() {
    window.open("https://stacklet.freshdesk.com/", "_blank");
    closeMenu();
  }

  const freshworksLoaded =
    document.querySelectorAll("#freshworks-frame").length > 0;

  return (
    <>
      {!freshworksLoaded ? (
        <Helmet>
          <script>
            {`window.fwSettings={'widget_id':72000002430};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
          </script>
          <script
            src={FRESHWORKS_WIDGET_SRC}
            type="text/javascript"
            async
            defer
          ></script>
          <script>{`FreshworksWidget('hide', 'launcher');`}</script>
          <script>
            {`
         FreshworksWidget('identify', 'ticketForm',
           {name: '${displayName}',
            email: '${email}',
            }); 
        `}
          </script>
        </Helmet>
      ) : null}
      {display === "button" ? (
        <>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            aria-label="support"
            color="inherit"
            onClick={handleMenu}
            size="large"
            title="Support"
          >
            <HelpOutlineOutlinedIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id="menu-appbar"
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
          >
            <MenuItem onClick={openSupportWidget}>Contact support</MenuItem>
            <MenuItem onClick={openHelpPortal}>Explore help portal</MenuItem>
          </Menu>
        </>
      ) : (
        <Link component="button" onClick={openSupportWidget}>
          <Typography color="textSecondary" variant="body2">
            Support
          </Typography>
        </Link>
      )}
    </>
  );
}
