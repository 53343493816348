import { useState } from "react";

import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

interface Props {
  onChange(enabled: boolean): void;
}

export function NoPolicyRecommendations({ onChange }: Props) {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                onChange(!checked);
              }}
            />
          }
          label="Include recommendations without a Stacklet policy"
        />
      </FormGroup>
      <Divider orientation="vertical" />
    </>
  );
}
