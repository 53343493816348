/**
 * @generated SignedSource<<484933fbe0a960c69331b75592b201f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopAccounts_topAccounts$data = {
  readonly topAccounts: ReadonlyArray<{
    readonly account: {
      readonly id: string;
      readonly name: string;
    };
    readonly cost: {
      readonly currency: string;
      readonly multiplier: number;
      readonly totalCost: number;
      readonly totalDays: number;
    };
  }>;
  readonly " $fragmentType": "TopAccounts_topAccounts";
};
export type TopAccounts_topAccounts$key = {
  readonly " $data"?: TopAccounts_topAccounts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopAccounts_topAccounts">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopAccounts_topAccounts",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "number",
          "value": 5
        }
      ],
      "concreteType": "AccountCost",
      "kind": "LinkedField",
      "name": "topAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceCost",
          "kind": "LinkedField",
          "name": "cost",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "multiplier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDays",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topAccounts(number:5)"
    }
  ],
  "type": "DashboardSection",
  "abstractKey": null
};

(node as any).hash = "0c2bf8f5783bee9e33e51e71fac393bd";

export default node;
