import { createContext, useContext } from "react";

export type Provider = "aws" | "azure" | "gcp" | "tencentcloud";

export const ProviderContext = createContext<Provider>("aws");

export const useProvider = () => {
  const result = useContext(ProviderContext);

  if (!result) {
    throw new Error("ProviderContext used outside of its Provider!");
  }

  return result;
};
