import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { graphql } from "react-relay";
import { useFragment } from "react-relay";

import type { AppVersion_version$key } from "./__generated__/AppVersion_version.graphql";

interface Props {
  platform: AppVersion_version$key;
}

export default function AppVersion({ platform }: Props) {
  const data = useFragment(
    graphql`
      fragment AppVersion_version on Platform {
        cloudCustodianVersion
        version
      }
    `,
    platform,
  );

  return (
    <Typography variant="body1">
      {`Stacklet ${data.version} (ui: ${import.meta.env.VITE_GIT_HASH}, c7n: `}{" "}
      <Link href="https://github.com/cloud-custodian/cloud-custodian/releases">
        {data.cloudCustodianVersion}
      </Link>
      )
    </Typography>
  );
}
