import * as Sentry from "@sentry/browser";
import { Tooltip } from "@stacklet/ui";
import { Button } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay";

import { useAlertContext } from "app/contexts/AlertContext";
import { ValidateAccountMutation } from "app/mutations";

import type { RevalidateAccount_account$key } from "./__generated__/RevalidateAccount_account.graphql";
import type {
  CloudProvider,
  ValidateAccountMutation as ValidateAccountMutationType,
} from "app/mutations/__generated__/ValidateAccountMutation.graphql";

interface Props {
  account: RevalidateAccount_account$key;
}

const RevalidateAccount = ({ account }: Props): JSX.Element => {
  const { alertDispatch } = useAlertContext();

  const [validateAccount] = useMutation<ValidateAccountMutationType>(
    ValidateAccountMutation,
  );

  const data = useFragment(
    graphql`
      fragment RevalidateAccount_account on Account {
        key
        provider
      }
    `,
    account,
  );

  const revalidate = (key: string, provider: CloudProvider) => {
    validateAccount({
      variables: {
        input: {
          provider,
          key,
        },
      },
      onCompleted: (response, errors) => {
        const account = response.validateAccount?.account;
        if (errors) {
          alertDispatch({
            message: `failed to revalidate account: ${errors[0].message}`,
            severity: "error",
          });
        } else if (account?.status && account?.status_message) {
          alertDispatch({
            message: account.status_message,
            severity: "warning",
          });
        } else {
          alertDispatch({
            message: "revalidating account",
            severity: "info",
          });
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };

  return (
    <Tooltip title="Validate security context">
      <Button
        aria-label="deploy binding"
        buttonType="main"
        dataTestID="account-detail-revalidate-button"
        onClick={() => {
          revalidate(data.key, data.provider);
        }}
      >
        Revalidate
      </Button>
    </Tooltip>
  );
};

export default RevalidateAccount;
