import Box from "@mui/material/Box";

interface Props {
  children?: React.ReactNode;
}

export default function InlineIcon({ children }: Props): JSX.Element {
  return (
    <Box component="span" sx={{ display: "inline-flex", alignItems: "center" }}>
      {children}
    </Box>
  );
}
