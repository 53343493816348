import { Auth } from "aws-amplify";
import LZString from "lz-string";
import Cookies from "universal-cookie";

import { getAuthCookieOptions } from "app/utils/auth";
import getConfigs from "app/utils/configs";

import type { HubCapsule } from "@aws-amplify/core";

const REDIRECT_COOKIE = "stacklet-return-uri";

export function storeRedirectReturnCookie() {
  // The callback URI (redirect_uri) that we pass to Cognito has to exactly
  // match a pre-registered URI in the user pool, so to preserve the entire URI
  // that the user requested, we have to store it in a cookie.
  const cookies = new Cookies();
  cookies.set(REDIRECT_COOKIE, window.location.href, getAuthCookieOptions());
}

function getRedirectReturnCookie() {
  // Return the REDIRECT_COOKIE value.
  const cookies = new Cookies();
  return cookies.get(REDIRECT_COOKIE);
}

export async function signInRedirectHandler(data: HubCapsule) {
  if (data.payload.event != "signIn") {
    return;
  }

  const redirectCookieValue = getRedirectReturnCookie();
  if (!redirectCookieValue) {
    return;
  }

  const redirectURL = new URL(redirectCookieValue);
  const redirectTarget = redirectURL.searchParams.get("redirect") || "";
  const redirectPath =
    (redirectURL.searchParams.get("redirectPath") || "") + redirectURL.hash;

  switch (redirectTarget) {
    case "docs":
      await openDocs(redirectPath);
      break;
    case "assetdb":
      await openRedash(redirectPath);
      break;
    case "api":
      await openGraphQLPlayground();
      break;
    case "":
      window.location.assign(redirectURL.href);
      break;
  }
}

export async function openDocs(path = "") {
  const configs = await getConfigs();
  if (configs.docs) {
    window.location.assign(configs.docs.docs_url + path);
  }
}

export async function openRedash(path = "") {
  const configs = await getConfigs();
  window.location.assign(configs.redash.redash_uri + path);
}

export async function openGraphQLPlayground() {
  const headers = {
    authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };

  const encodedExplorerURLState = LZString.compressToEncodedURIComponent(
    JSON.stringify({
      headers: JSON.stringify(headers),
    }),
  );

  const configs = await getConfigs();

  const encodedConfig = encodeURIComponent(configs.deployment.gql_uri);

  const targetUri = `https://studio.apollographql.com/sandbox/explorer?endpoint=${encodedConfig}&explorerURLState=${encodedExplorerURLState}`;

  window.location.assign(targetUri);
}
