import Backdrop from "@mui/material/Backdrop";

interface Props {
  children: React.ReactNode;
}

export function BackdropFallback({ children }: Props): JSX.Element {
  return (
    <Backdrop
      sx={{ color: "common.white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      {children}
    </Backdrop>
  );
}
