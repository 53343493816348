import { graphql } from "react-relay";

export const RemoveAccountGroupMappingsMutation = graphql`
  mutation RemoveAccountGroupMappingsMutation(
    $input: RemoveAccountGroupMappingsInput!
  ) {
    removeAccountGroupMappings(input: $input) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
