import type { Permission } from "app/contexts/UserContext";

export function isEqual(
  permissionA: Permission,
  permissionB: Permission,
): boolean {
  return (
    permissionA.action === permissionB.action &&
    permissionA.object === permissionB.object
  );
}
