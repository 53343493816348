import Typography from "@mui/material/Typography";

import { Link } from "app/components";

export default function Copyright(): JSX.Element {
  return (
    <Typography data-testid="copyright" variant="body1">
      {"Copyright © "}
      <Link href="https://stacklet.io">Stacklet</Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  );
}
