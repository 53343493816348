/**
 * @generated SignedSource<<507815dfecae29c91433d61f0813f7c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
export type DocumentationQuery$variables = Record<PropertyKey, never>;
export type DocumentationQuery$data = {
  readonly UrlConfig: {
    readonly docs: string | null | undefined;
  } | null | undefined;
};
export type DocumentationQuery = {
  response: DocumentationQuery$data;
  variables: DocumentationQuery$variables;
};

const node: ClientRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UrlConfig",
        "kind": "LinkedField",
        "name": "UrlConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docs",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DocumentationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "529dfecfbdb62e9aea303a5ef7f84d49",
    "id": null,
    "metadata": {},
    "name": "DocumentationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dc2c7b00c489c4a320326bd101b09726";

export default node;
