import { useEffect, useState } from "react";

import { type Location, useLocation } from "react-router-dom";

type LocationChangeCallback = (
  location: Location,
  prevLocation: Location | null,
) => void;

/**
 * Run a function when the route changes.
 */
export function useLocationChange(fn: LocationChangeCallback): void {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<Location | null>(null);

  useEffect(() => {
    fn(location, prevLocation);
    setPrevLocation(location);
  }, [location, prevLocation, fn]);
}
