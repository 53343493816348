import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import type { BoxProps } from "@mui/material/Box";
import type { Theme } from "@mui/material/styles";

type CollapseIconProps = { isMainCollapsed?: boolean; theme?: Theme };

const CollapseIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMainCollapsed",
})<CollapseIconProps & BoxProps>(({ isMainCollapsed, theme }) => ({
  color: theme?.palette.common.white,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  padding: isMainCollapsed ? theme?.spacing(2, 0) : theme?.spacing(1, 0),
  "&:first-of-type": {
    paddingTop: theme?.spacing(2),
  },
  "&:last-child": {
    paddingBottom: theme?.spacing(2),
  },
}));

export default CollapseIcon;
