import type { ReactElement } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@stacklet/ui";
import { useNavigate } from "react-router-dom";

export default function NotFound(): ReactElement {
  const navigate = useNavigate();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
    >
      <Typography
        component="div"
        sx={{
          mb: 1,
          fontSize: (theme) => theme.typography.pxToRem(72),
          fontWeight: 700,
        }}
      >
        Error 404
      </Typography>
      <Typography component="div" sx={{ mb: 4 }} variant="body2">
        The page you were looking for could not be found
      </Typography>
      <Button buttonType="main" onClick={() => navigate(-1)}>
        Go back
      </Button>
    </Box>
  );
}
