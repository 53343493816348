import { graphql } from "react-relay";

export const RemovePolicyCollectionMappingsMutation = graphql`
  mutation RemovePolicyCollectionMappingsMutation(
    $input: RemovePolicyCollectionMappingsInput!
  ) {
    removePolicyCollectionMappings(input: $input) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
