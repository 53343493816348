import { useLayoutEffect, useRef, useMemo } from "react";

import { json } from "@codemirror/lang-json";
import { yaml } from "@codemirror/lang-yaml";
import { StreamLanguage } from "@codemirror/language";
import { jinja2 } from "@codemirror/legacy-modes/mode/jinja2";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { COLORS } from "@stacklet/ui";
import { githubLightInit } from "@uiw/codemirror-theme-github";
import { useCodeMirror } from "@uiw/react-codemirror";

import { useAlertContext } from "app/contexts";

export type EditorLanguage = "jinja2" | "json" | "yaml";

interface Props {
  onChange?: (template: string) => void;
  buffer?: string;
  readOnly?: boolean;
  lang: EditorLanguage;
}

// HACK: @codemirror/legacy-modes/mode/jinja2 loads both cjs and esm state modules
const TEST_RUN = import.meta.env.VITEST_WORKER_ID !== undefined;

export function TextEditor({
  onChange,
  buffer = "",
  readOnly = false,
  lang,
}: Props) {
  const extensions = useMemo(
    () =>
      TEST_RUN
        ? []
        : [
            ...(lang === "yaml" ? [yaml()] : []),
            ...(lang === "jinja2" ? [StreamLanguage.define(jinja2)] : []),
            ...(lang === "json" ? [json()] : []),
          ],
    [lang],
  );
  const editor = useRef<HTMLDivElement>(null);
  const { alertDispatch } = useAlertContext();

  const { setContainer } = useCodeMirror({
    autoFocus: true,
    container: editor.current,
    extensions,
    onChange,
    readOnly,
    value: buffer,
    theme: githubLightInit({
      settings: {
        gutterBackground: "#F4F4F5",
      },
    }),
  });

  useLayoutEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [setContainer]);

  return (
    <Box>
      {readOnly ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="copy"
            onClick={() => {
              navigator.clipboard.writeText(buffer || "");
              alertDispatch({
                message: "Copied source to clipboard",
                severity: "success",
              });
            }}
            size="large"
            sx={{ mb: -5, zIndex: 10 }}
            title="Copy source"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      ) : null}
      <Box ref={editor} sx={{ border: 1, borderColor: COLORS.navy.L50 }} />
    </Box>
  );
}
