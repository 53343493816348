import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import IconButton from "@mui/material/IconButton";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";

import type { DocumentationQuery } from "./__generated__/DocumentationQuery.graphql";

export function Documentation() {
  const data = useLazyLoadQuery<DocumentationQuery>(
    graphql`
      query DocumentationQuery {
        UrlConfig {
          docs
        }
      }
    `,
    {},
  );

  const openDocs = () => {
    if (data.UrlConfig?.docs) {
      window.open(data.UrlConfig?.docs, "_blank");
    }
  };

  return (
    <IconButton
      aria-controls="menu-appbar"
      aria-haspopup="true"
      aria-label="documentation"
      color="inherit"
      onClick={openDocs}
      size="large"
      title="Documentation"
    >
      <FeedOutlinedIcon />
    </IconButton>
  );
}
