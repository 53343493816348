import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import DOMPurify from "dompurify";

import { type EditorLanguage, TextEditor } from "app/components/TextEditor";

interface Props {
  source: string;
  lang: EditorLanguage;
  title?: string;
}

export function SourceView({ source, lang, title = "" }: Props) {
  if (!source) {
    return null;
  }

  let content;
  if (lang === "json") {
    content = DOMPurify.sanitize(JSON.stringify(JSON.parse(source), null, 2));
  } else {
    content = DOMPurify.sanitize(source);
  }

  return (
    <Box data-testid="source-view" sx={{ mt: 0.5 }}>
      {title ? (
        <Typography sx={{ mb: 1 }} variant="h4">
          {title}
        </Typography>
      ) : null}
      <TextEditor buffer={content} lang={lang} readOnly />
    </Box>
  );
}
