import type { CloudProvider } from "app/mutations/__generated__/AddAccountGroupMutation.graphql";

// Translate provider config value to GraphQL CloudProvider type
export function formatProvider(provider: string): CloudProvider {
  switch (provider) {
    case "aws":
      return "AWS";
    case "gcp":
      return "GCP";
    case "azure":
      return "Azure";
    case "k8s":
      return "Kubernetes";
    case "tencentcloud":
      return "TencentCloud";
    default:
      return "AWS";
  }
}
