/**
 * @generated SignedSource<<e65bb0a4e890490c9a9f632f8c7133f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OverallCompliance_dashboard$data = {
  readonly key: string;
  readonly metric: string | null | undefined;
  readonly providers: ReadonlyArray<CloudProvider> | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "OverallCompliance_dashboard";
};
export type OverallCompliance_dashboard$key = {
  readonly " $data"?: OverallCompliance_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverallCompliance_dashboard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverallCompliance_dashboard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metric",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};

(node as any).hash = "597a29087b76235bf0388b2440092bcb";

export default node;
