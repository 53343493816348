import { useEffect } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DataGridPro, gridClasses, useGridApiRef } from "@mui/x-data-grid-pro";
import { COLORS } from "@stacklet/ui";

import { useStickyState } from "app/hooks";

import { useColumnSetup } from "./hooks";
import CustomLoadingOverlay from "./LoadingOverlay";
import CustomNoRowsOverlay from "./NoRowsOverlay";
import CustomFooter from "./XGridCustomFooter";
import CustomToolbar from "./XGridCustomToolbar";

import type {
  DataGridProProps,
  GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import type { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

const PREFIX = "XGridTable";

const autosizeOptions = {
  includeHeaders: true,
  includeOutliers: true,
};

const classes = {
  clickableRow: `${PREFIX}-clickableRow`,
};

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    borderLeft: `1px solid ${COLORS.navy.L90}`,
    borderRight: `1px solid ${COLORS.navy.L90}`,
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "white",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "white",
    },
  },
  backgroundColor: "transparent",
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(0.5),
  "& ::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "7px",
    height: "7px",
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    backgroundColor: "rgba(0,0,0,.5)",
    boxShadow: "0 0 1px rgba(255,255,255,.5)",
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiButton-textPrimary": {
    color: COLORS.orange.base,
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnsHeaders": {
    backgroundColor: "#F1EDE9",
  },
  [`& .${classes.clickableRow}.MuiDataGrid-row`]: {
    "&:hover": {
      boxShadow: "inset 5px 0 #0c5688",
      cursor: "pointer",
    },
  },
  "& .MuiDataGrid-columnHeaderTitleContainer:hover": {
    cursor: "pointer",
  },
}));

interface CustomProps {
  tableId: string;
  autoHeightMax?: number;
  columnBuffer?: number;
  onColumnHeaderClick?: (params: GridColumnHeaderParams) => void;
  handleExport?: () => void;
  hasToolbar?: boolean;
  customHeight?: number | string;
  exportInProgress?: boolean;
  customInline?: React.ReactNode;
  enableFilterButton?: boolean;
}

type Props = DataGridProProps & CustomProps;

export default function StyledDataGrid({
  autoHeightMax,
  customHeight,
  disableVirtualization,
  handleExport,
  hasToolbar = true,
  exportInProgress,
  initialState,
  loading,
  onColumnHeaderClick,
  tableId,
  rowCount,
  rows,
  customInline,
  enableFilterButton = false,
  ...rest
}: Props) {
  const [tableState, setTableState] = useStickyState<GridInitialStatePro>(
    {},
    `table-state-${tableId}`,
  );

  const apiRef = useGridApiRef();

  const maxAutoHeightRows = autoHeightMax || 30;
  const totalRows = rowCount || rows.length;

  const { columns: rawColumns } = rest;
  const columns = useColumnSetup(rawColumns, totalRows);

  // we set this to disable table virtualisation and resizing for test runs
  const TEST_RUN = import.meta.env.VITEST_WORKER_ID !== undefined;

  useEffect(() => {
    if (!loading && rows.length && !TEST_RUN) {
      // Even with the useEffect, there can be a small race between the
      // rendering and autosizing leading to column sizes that are just
      // slightly off. See https://stackoverflow.com/a/78411672
      setTimeout(() => apiRef.current.autosizeColumns(autosizeOptions), 250);
    }
  }, [loading, rows, apiRef, TEST_RUN]);

  return (
    <Box
      display="flex"
      flexBasis="column"
      height={
        totalRows < maxAutoHeightRows
          ? "100%"
          : customHeight || `calc(100vh - 250px)`
      }
      minHeight={totalRows < maxAutoHeightRows ? `${totalRows * 10}px` : "30vh"}
    >
      <Box sx={{ width: "100%", display: "grid" }}>
        <StyledDataGridPro
          apiRef={apiRef}
          autoHeight={totalRows < maxAutoHeightRows}
          autosizeOnMount={!TEST_RUN}
          autosizeOptions={autosizeOptions}
          {...rest}
          columns={columns}
          disableVirtualization={TEST_RUN || disableVirtualization}
          initialState={
            tableState
              ? {
                  ...tableState,
                  ...initialState,
                }
              : undefined
          }
          loading={loading}
          localeText={{ toolbarColumns: "", toolbarFilters: "" }} // remove labels from filter and column buttons
          onColumnHeaderClick={onColumnHeaderClick}
          onStateChange={() => {
            if (apiRef?.current?.exportState) {
              setTableState(apiRef.current.exportState());
            }
          }}
          rowHeight={40}
          rows={rows}
          slotProps={{
            footer: {
              totalRows,
            },
            panel: {
              placement: "bottom-end",
            },
            toolbar: {
              exportInProgress: exportInProgress,
              handleExport,
              customInline,
              enableFilterButton,
            },
          }}
          slots={{
            footer: CustomFooter,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: hasToolbar ? CustomToolbar : null,
          }}
          disableColumnReorder
          hideFooterPagination
          hideFooterRowCount
        />
      </Box>
    </Box>
  );
}
