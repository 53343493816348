/**
 * @generated SignedSource<<6a8641e29760f82bad6e12cc93ca7bfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportSource = "BINDING" | "CONTROL" | "POLICY" | "%future added value";
export type UpsertReportGroupsInput = {
  reportGroups?: ReadonlyArray<ReportGroupInput | null | undefined> | null | undefined;
};
export type ReportGroupInput = {
  bindings?: ReadonlyArray<string> | null | undefined;
  controls?: ReadonlyArray<string> | null | undefined;
  emailSettings?: ReadonlyArray<EmailSettingsInput | null | undefined> | null | undefined;
  enabled?: boolean | null | undefined;
  groupBy: ReadonlyArray<string | null | undefined>;
  jiraSettings?: ReadonlyArray<JiraSettingsInput | null | undefined> | null | undefined;
  name: string;
  schedule: string;
  serviceNowSettings?: ReadonlyArray<ServiceNowSettingsInput | null | undefined> | null | undefined;
  slackSettings?: ReadonlyArray<SlackSettingsInput | null | undefined> | null | undefined;
  source: ReportSource;
  symphonySettings?: ReadonlyArray<SymphonySettingsInput | null | undefined> | null | undefined;
  teamsSettings?: ReadonlyArray<TeamsSettingsInput | null | undefined> | null | undefined;
  useMessageSettings: boolean;
};
export type EmailSettingsInput = {
  cc?: ReadonlyArray<string | null | undefined> | null | undefined;
  firstMatchOnly?: boolean | null | undefined;
  format?: string | null | undefined;
  fromEmail?: string | null | undefined;
  priority?: string | null | undefined;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  subject: string;
  template: string;
};
export type RecipientInput = {
  account_owner?: boolean | null | undefined;
  event_owner?: boolean | null | undefined;
  resource_owner?: boolean | null | undefined;
  tag?: string | null | undefined;
  value?: string | null | undefined;
};
export type SlackSettingsInput = {
  firstMatchOnly?: boolean | null | undefined;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  template: string;
};
export type TeamsSettingsInput = {
  firstMatchOnly?: boolean | null | undefined;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  template: string;
};
export type ServiceNowSettingsInput = {
  firstMatchOnly?: boolean | null | undefined;
  impact: string;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  shortDescription: string;
  template: string;
  urgency: string;
};
export type JiraSettingsInput = {
  description: string;
  firstMatchOnly?: boolean | null | undefined;
  project: string;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  summary: string;
  template: string;
};
export type SymphonySettingsInput = {
  firstMatchOnly?: boolean | null | undefined;
  recipients?: ReadonlyArray<RecipientInput> | null | undefined;
  template: string;
};
export type UpsertReportGroupsMutation$variables = {
  connections: ReadonlyArray<string>;
  input?: UpsertReportGroupsInput | null | undefined;
};
export type UpsertReportGroupsMutation$data = {
  readonly upsertReportGroups: {
    readonly reportGroups: ReadonlyArray<{
      readonly deliverySettings: ReadonlyArray<{
        readonly firstMatchOnly?: boolean | null | undefined;
        readonly impact?: string;
        readonly project?: string;
        readonly recipients?: ReadonlyArray<{
          readonly account_owner: boolean | null | undefined;
          readonly event_owner: boolean | null | undefined;
          readonly resource_owner: boolean | null | undefined;
          readonly tag: string | null | undefined;
          readonly value: string | null | undefined;
        }> | null | undefined;
        readonly shortDescription?: string;
        readonly template?: string;
        readonly type?: string;
        readonly urgency?: string;
      } | null | undefined> | null | undefined;
      readonly enabled: boolean;
      readonly groupBy: ReadonlyArray<string> | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly schedule: string;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type UpsertReportGroupsMutation = {
  response: UpsertReportGroupsMutation$data;
  variables: UpsertReportGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstMatchOnly",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_owner",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resource_owner",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event_owner",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipient",
  "kind": "LinkedField",
  "name": "recipients",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "template",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "type": "EmailSettings",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    (v7/*: any*/),
    (v9/*: any*/)
  ],
  "type": "SlackSettings",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipient",
  "kind": "LinkedField",
  "name": "recipients",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tag",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "project",
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "type": "JiraSettings",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impact",
      "storageKey": null
    },
    (v8/*: any*/),
    (v12/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urgency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    }
  ],
  "type": "ServiceNowSettings",
  "abstractKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupBy",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedule",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertReportGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertReportGroupsPayload",
        "kind": "LinkedField",
        "name": "upsertReportGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportGroup",
            "kind": "LinkedField",
            "name": "reportGroups",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "deliverySettings",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertReportGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertReportGroupsPayload",
        "kind": "LinkedField",
        "name": "upsertReportGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportGroup",
            "kind": "LinkedField",
            "name": "reportGroups",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "deliverySettings",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "reportGroups",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ReportGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4c2a7d4d5429cc0e6d3177f3c308554",
    "id": null,
    "metadata": {},
    "name": "UpsertReportGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertReportGroupsMutation(\n  $input: UpsertReportGroupsInput\n) {\n  upsertReportGroups(input: $input) {\n    reportGroups {\n      deliverySettings {\n        __typename\n        ... on EmailSettings {\n          firstMatchOnly\n          recipients {\n            value\n            account_owner\n            resource_owner\n            event_owner\n          }\n          type\n          template\n        }\n        ... on SlackSettings {\n          firstMatchOnly\n          type\n          recipients {\n            value\n            account_owner\n            resource_owner\n            event_owner\n          }\n          template\n        }\n        ... on JiraSettings {\n          firstMatchOnly\n          type\n          recipients {\n            value\n            tag\n            account_owner\n            resource_owner\n            event_owner\n          }\n          project\n          template\n        }\n        ... on ServiceNowSettings {\n          firstMatchOnly\n          impact\n          type\n          recipients {\n            value\n            tag\n            account_owner\n            resource_owner\n            event_owner\n          }\n          template\n          urgency\n          shortDescription\n        }\n      }\n      id\n      enabled\n      name\n      groupBy\n      schedule\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "94757626695af1e1c980a137ded2cffe";

export default node;
