/**
 * @generated SignedSource<<cc8de982aea16566079ea3cc7da8b24b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type AccountIdAutocompleteRefetchQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
};
export type AccountIdAutocompleteRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountIdAutocomplete_accounts">;
};
export type AccountIdAutocompleteRefetchQuery = {
  response: AccountIdAutocompleteRefetchQuery$data;
  variables: AccountIdAutocompleteRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountIdAutocompleteRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccountIdAutocomplete_accounts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountIdAutocompleteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filterElement",
            "variableName": "filterElement"
          },
          (v1/*: any*/)
        ],
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "acf9d529188cde6b089483f2ce8c4aad",
    "id": null,
    "metadata": {},
    "name": "AccountIdAutocompleteRefetchQuery",
    "operationKind": "query",
    "text": "query AccountIdAutocompleteRefetchQuery(\n  $filterElement: FilterElementInput\n  $first: Int = 10\n) {\n  ...AccountIdAutocomplete_accounts_3ASum4\n}\n\nfragment AccountIdAutocomplete_accounts_3ASum4 on Query {\n  accounts(filterElement: $filterElement, first: $first) {\n    edges {\n      node {\n        key\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dce42049224744784ee7f1404891fea8";

export default node;
