import { DEFAULT_PAGE_SIZE } from "app/utils/consts";

export function useLoadNext(
  hasNext: boolean,
  loadNext: (pageSize: number) => void,
): () => void {
  const loadMore = (): void => {
    if (hasNext) {
      loadNext(DEFAULT_PAGE_SIZE);
    }
  };

  return loadMore;
}
