/**
 * @generated SignedSource<<8d4b955477fca4c9f4b0d9f1b55f002e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type PolicyCollectionDetailsQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  uuid?: string | null | undefined;
};
export type PolicyCollectionDetailsQuery$data = {
  readonly policyCollection: {
    readonly autoUpdate: boolean;
    readonly description: string | null | undefined;
    readonly name: string;
    readonly policyMappings: {
      readonly pageInfo: {
        readonly __id: string;
        readonly total: number | null | undefined;
      };
    };
    readonly provider: CloudProvider | null | undefined;
    readonly system: boolean;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"PolicyCollectionBindings_bindings" | "PolicyCollectionPolicies_policyMappings">;
  } | null | undefined;
};
export type PolicyCollectionDetailsQuery = {
  response: PolicyCollectionDetailsQuery$data;
  variables: PolicyCollectionDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v3 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoUpdate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "system",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = [
  (v8/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endCursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PolicyCollectionDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PolicyCollection",
        "kind": "LinkedField",
        "name": "policyCollection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PolicyCollectionBindings_bindings"
          },
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "PolicyCollectionPolicies_policyMappings"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PolicyCollectionMappingConnection",
            "kind": "LinkedField",
            "name": "policyMappings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PolicyCollectionDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PolicyCollection",
        "kind": "LinkedField",
        "name": "policyCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroup",
                        "kind": "LinkedField",
                        "name": "accountGroup",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PolicyCollection",
                        "kind": "LinkedField",
                        "name": "policyCollection",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastDeployed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BindingRunConnection",
                        "kind": "LinkedField",
                        "name": "runs",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BindingRunEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BindingRun",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startTime",
                                    "storageKey": null
                                  },
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "PolicyCollectionBindings_bindings",
            "kind": "LinkedHandle",
            "name": "bindings"
          },
          (v13/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PolicyCollectionMappingConnection",
            "kind": "LinkedField",
            "name": "policyMappings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollectionMappingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PolicyCollectionMapping",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Policy",
                        "kind": "LinkedField",
                        "name": "policy",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "version",
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "PolicyCollectionPolicies_policyMappings",
            "kind": "LinkedHandle",
            "name": "policyMappings"
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0f98292f6c77fa3a9da4fca6cd5be7f",
    "id": null,
    "metadata": {},
    "name": "PolicyCollectionDetailsQuery",
    "operationKind": "query",
    "text": "query PolicyCollectionDetailsQuery(\n  $uuid: String\n  $first: Int\n  $after: String\n) {\n  policyCollection(uuid: $uuid) {\n    ...PolicyCollectionBindings_bindings\n    ...PolicyCollectionPolicies_policyMappings_2HEEH6\n    policyMappings(first: $first, after: $after) {\n      pageInfo {\n        total\n      }\n    }\n    uuid\n    name\n    description\n    provider\n    autoUpdate\n    system\n    id\n  }\n}\n\nfragment PolicyCollectionBindings_bindings on PolicyCollection {\n  bindings {\n    edges {\n      node {\n        id\n        name\n        accountGroup {\n          name\n          id\n        }\n        policyCollection {\n          name\n          id\n        }\n        lastDeployed\n        runs {\n          edges {\n            node {\n              startTime\n              id\n            }\n          }\n        }\n        uuid\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      total\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment PolicyCollectionPolicies_policyMappings_2HEEH6 on PolicyCollection {\n  policyMappings(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        policy {\n          uuid\n          name\n          version\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "5215eb63705a12e11ce39d55efae9cfa";

export default node;
