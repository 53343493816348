import { useEffect, useState } from "react";

import * as Sentry from "@sentry/browser";
import { Tooltip } from "@stacklet/ui";
import { Button } from "@stacklet/ui";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import { DeleteConfirmationDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts";
import { RemoveAccountGroupMutation } from "app/mutations";

import type { RemoveAccountGroupMutation as RemoveAccountGroupMutationType } from "app/mutations/__generated__/RemoveAccountGroupMutation.graphql";

interface Props {
  accountGroupUuid: string;
  isSystemCreated?: boolean;
}

export function Remove({ accountGroupUuid, isSystemCreated = false }: Props) {
  const { alertDispatch } = useAlertContext();
  const navigate = useNavigate();

  const [isDeleteConfirmDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);
  const [deleteUuid, setDeleteUuid] = useState<string>("");

  useEffect(() => {
    setDeleteUuid(accountGroupUuid);

    return () => {
      setDeleteUuid("");
    };
  }, [accountGroupUuid]);

  const [remove] = useMutation<RemoveAccountGroupMutationType>(
    RemoveAccountGroupMutation,
  );

  const openDialog = (value: boolean) => {
    setIsConfirmDeleteDialogOpen(value);
  };

  const confirmDelete = () => {
    deleteAccountGroup(deleteUuid);
  };

  const onDeleteFailure = (errorMessage: string) => {
    alertDispatch({
      message: `failed to remove account group: ${errorMessage}`,
      severity: "error",
    });
  };

  const deleteAccountGroup = (uuid: string) => {
    remove({
      variables: {
        uuid,
      },
      onCompleted(response, errors) {
        if (errors) {
          errors.forEach((error) => {
            onDeleteFailure(error.message);
          });
        } else {
          alertDispatch({
            message: "successfully removed account group",
            severity: "success",
          });
        }
        setDeleteUuid("");
        setIsConfirmDeleteDialogOpen(false);
        navigate("/admin/account-groups");
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };
  const tooltipTitle = isSystemCreated
    ? "Can not remove system created account groups"
    : "";

  return (
    <>
      <Tooltip data-testid="remove-ag-tooltip" title={tooltipTitle}>
        <span>
          <Button
            buttonType="destructive"
            dataTestID="remove-account-group"
            isDisabled={isSystemCreated}
            onClick={() => {
              setIsConfirmDeleteDialogOpen(true);
              setDeleteUuid(accountGroupUuid);
            }}
          >
            Remove
          </Button>
        </span>
      </Tooltip>
      <DeleteConfirmationDialog
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDialog}
        title="Account Group"
      />
    </>
  );
}
