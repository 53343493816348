import { nanoid } from "nanoid";
import { commitLocalUpdate } from "react-relay";
import { getRequest, createOperationDescriptor, graphql } from "relay-runtime";

import getConfigs from "app/utils/configs";
import environment from "RelayEnvironment";

import type { Environment } from "react-relay";

export function setIsOnboardingSkipped(skipped: boolean): void {
  commitLocalUpdate(environment, (store) => {
    const whoami = store.getRoot().getLinkedRecord("whoami");
    const dataID = `client:Config:${nanoid()}`;
    const config = store.create(dataID, "Config");
    config.setValue(skipped, "isOnboardingSkipped");
    whoami?.setLinkedRecord(config, "config");
  });
}

export async function createUrlConfig(environment: Environment) {
  const configs = await getConfigs();
  const configDocs = configs.docs;
  const configJun0 = configs.jun0;
  const configRedash = configs.redash;
  const configSinistral = configs.sinistral;

  // Updating Relay Store
  commitLocalUpdate(environment, (store) => {
    const fieldKey = "UrlConfig";
    const __typename = "UrlConfig";

    const dataID = `client:${__typename}`;
    // only create UrlConfig record once
    if (store.get(dataID)) {
      return;
    }
    const record = store.create(dataID, __typename);

    record.setValue(configDocs?.docs_url, "docs");
    record.setValue(configRedash?.redash_uri, "redash");
    record.setValue(configSinistral?.sinistral_url, "sinistral");
    record.setValue(configJun0?.jun0_url, "jun0");

    const localDataQuery = graphql`
      query localStateQuery {
        UrlConfig {
          __typename
        }
      }
    `;

    const request = getRequest(localDataQuery);
    const operation = createOperationDescriptor(request, {});
    environment.retain(operation);

    store.getRoot().setLinkedRecord(record, fieldKey);
  });
}
