export enum BindingStatusEnum {
  STARTING,
  RUNNING,
  NOT_RUNNING,
}

export type BindingProgress = {
  bindingUuid?: string;
  elapsedTime: string;
  errorCount?: number;
  expectedExecutions: number;
  lastUpdate?: string;
  savedExecutions: number;
  startTime?: string;

  progressPercent: number;
  errorPercent: number;
  status?: BindingStatusEnum;
};
