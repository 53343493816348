/**
 * @generated SignedSource<<a10bf6396c91be9fef3b27febed4809c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicyCollectionAutocomplete_policyCollections$data = {
  readonly policyCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
      };
    }>;
  };
  readonly " $fragmentType": "PolicyCollectionAutocomplete_policyCollections";
};
export type PolicyCollectionAutocomplete_policyCollections$key = {
  readonly " $data"?: PolicyCollectionAutocomplete_policyCollections$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyCollectionAutocomplete_policyCollections">;
};

import PolicyCollectionAutocompleteRefetchQuery_graphql from './PolicyCollectionAutocompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterElement"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": PolicyCollectionAutocompleteRefetchQuery_graphql
    }
  },
  "name": "PolicyCollectionAutocomplete_policyCollections",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "PolicyCollectionConnection",
      "kind": "LinkedField",
      "name": "policyCollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PolicyCollectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PolicyCollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "21c02826e86977aa6b12cbb1344dd573";

export default node;
