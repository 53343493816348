import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export function CardSkeleton() {
  return (
    <Box sx={{ m: "55px" }}>
      <Skeleton
        animation="wave"
        height="90px"
        role="progressbar"
        variant="circular"
        width="90px"
      />
    </Box>
  );
}
