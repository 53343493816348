import InfoIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@stacklet/ui";
import pluralize from "pluralize";

import { Currency } from "../Currency";

interface Props {
  totalCost?: number;
  totalDays?: number;
  multiplier?: number;
  currency?: string;
}

export function DailyCost({
  totalCost,
  totalDays = 1,
  multiplier = 1000,
  currency = "USD",
}: Props) {
  if (!totalCost || !totalDays) {
    return <span>--</span>;
  }

  /* Cost is stored in milli */
  const dailyCost = totalCost / totalDays / multiplier;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  return (
    <Stack alignItems="center" direction="row" justifyContent="right">
      <Currency amount={dailyCost} currency={currency} />
      <Tooltip
        title={`Based on ${totalDays} ${pluralize(
          "day",
          totalDays,
        )} of cost data. Total cost was ${formatter.format(
          totalCost / multiplier,
        )}`}
      >
        <InfoIcon
          data-testid="daily-cost-icon"
          fontSize="inherit"
          sx={{ ml: 0.5 }}
        />
      </Tooltip>
    </Stack>
  );
}
