import { GridOverlay } from "@mui/x-data-grid-pro";
import { useSearchParams } from "react-router-dom";

export default function CustomNoRowsOverlay(): JSX.Element {
  const [searchParams] = useSearchParams({});

  const search = searchParams.get("search");
  const filters = searchParams.get("filters");

  return (
    <GridOverlay>
      {search || filters ? (
        <div>No rows matched search or filters</div>
      ) : (
        <div>No Rows</div>
      )}
    </GridOverlay>
  );
}
