import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { COLORS } from "@stacklet/ui";

import AwsResourcesIcon from "app/components/icons/AwsResourcesIcon";

const ItemDetailRow = styled("span", {
  shouldForwardProp: (prop) => prop !== "withBg",
})<{ withBg?: boolean }>(({ theme, withBg }) => ({
  fontWeight: 400,
  backgroundColor: withBg ? COLORS.navy.L95 : "transparent",
  padding: withBg ? theme?.spacing(0.5, 1) : 0,
  borderRadius: withBg ? "4px" : 0,
}));

export const Title = styled("span")(({ theme }) => ({
  padding: theme?.spacing(0.5, 1, 0.5, 0),
  textTransform: "uppercase",
  opacity: 0.8,
  color: COLORS.navy.L30,
  fontSize: theme.typography.pxToRem(10),
  fontWeight: 800,
}));

const ItemDetailColumn = styled("div", {
  shouldForwardProp: (prop) => prop !== "withBg",
})<{ withBg?: boolean }>(({ theme, withBg }) => ({
  fontWeight: 400,
  backgroundColor: withBg ? COLORS.navy.L95 : "transparent",
  padding: withBg ? theme?.spacing(0.5, 1) : 0,
  borderRadius: withBg ? "4px" : 0,
}));

interface Props {
  children: React.ReactNode;
  title: string;
  direction: "row" | "column";
  withBg?: boolean;
  value?: string | number | boolean | JSX.Element | null;
  showResourceIcon?: boolean;
}

export default function DetailGridValue({
  children,
  title,
  value,
  direction,
  withBg,
  showResourceIcon,
}: Props) {
  const resourceType =
    showResourceIcon && value && typeof value === "string"
      ? value.split(".")[1]
      : null;

  return (
    <>
      <Title>{title}</Title>
      {direction === "row" ? (
        <>
          {children ? (
            <span>{children}</span>
          ) : (
            <ItemDetailRow
              aria-label={title.toLowerCase()}
              data-testid={title.split(" ").join("-").toLowerCase()}
              withBg={withBg}
            >
              {value || "--"}
              {resourceType ? <AwsResourcesIcon resource={resourceType} /> : ""}
            </ItemDetailRow>
          )}
        </>
      ) : (
        <>
          {children ? (
            <Box>{children}</Box>
          ) : (
            <ItemDetailColumn
              aria-label={title.toLowerCase()}
              data-testid={title.split(" ").join("-").toLowerCase()}
            >
              {value || "--"}
            </ItemDetailColumn>
          )}
        </>
      )}
    </>
  );
}
