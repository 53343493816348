import { styled } from "@mui/material/styles";
import SparkMD5 from "spark-md5";

import { backgroundColorFormatter, initialsFormatter } from "./formatter";

interface Props {
  name: string;
  email: string;
  size?: number;
}

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "emailMd5" && prop !== "size",
})<{ emailMd5: string; size?: number }>(({ emailMd5, size = 24 }) => ({
  position: "relative",
  width: size,
  height: size,
  borderRadius: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  ...backgroundColorFormatter(emailMd5),
}));

const InitialsWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "initials" && prop !== "size",
})<{ initials: string; size?: number }>(({ initials, size = 24 }) => ({
  fontSize: size / (1 * Math.max(initials.length, 2)),
  position: "absolute",
  fontFamily: "sans-serif",
  userSelect: "none",
  textTransform: "uppercase",
}));

const Image = styled("div", {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "imgUrl",
})<{ size?: number; imgUrl?: string }>(({ size, imgUrl }) => ({
  backgroundImage: `url(${imgUrl})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  width: size,
  height: size,
  top: 0,
  left: 0,
  borderRadius: "50%",
}));

export const Avatar = ({ email, name, size = 24 }: Props): JSX.Element => {
  const initials = initialsFormatter(name);
  const emailMd5 = SparkMD5.hash(email);

  const sizeStr = Math.max(size, 250).toString();
  const url = `https://www.gravatar.com/avatar/${emailMd5}?s=${sizeStr}&d=blank`;

  return (
    <Container emailMd5={emailMd5} size={size}>
      <InitialsWrapper initials={initials} size={size}>
        {initials}
      </InitialsWrapper>
      <Image imgUrl={url} size={size} />
    </Container>
  );
};
