import type React from "react";
import { useEffect, useRef } from "react";

import type { TabsActions } from "@mui/material/Tabs";

export function useTabsRef(): React.RefObject<TabsActions> {
  const tabRef = useRef<TabsActions>(null);

  useEffect(() => {
    setTimeout(() => {
      tabRef.current?.updateIndicator();
    }, 400);
  });

  return tabRef;
}
