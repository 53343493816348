/**
 * @generated SignedSource<<043df0031f1e1d1645050fa11fcc5f8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicySeverityAutocomplete_severity$data = {
  readonly policySeverityValues: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly severity: string;
      };
    }>;
  };
  readonly " $fragmentType": "PolicySeverityAutocomplete_severity";
};
export type PolicySeverityAutocomplete_severity$key = {
  readonly " $data"?: PolicySeverityAutocomplete_severity$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicySeverityAutocomplete_severity">;
};

import PolicySeverityAutocompleteRefetchQuery_graphql from './PolicySeverityAutocompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": PolicySeverityAutocompleteRefetchQuery_graphql
    }
  },
  "name": "PolicySeverityAutocomplete_severity",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "PolicySeverityValueConnection",
      "kind": "LinkedField",
      "name": "policySeverityValues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PolicySeverityValueEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PolicySeverityValue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "severity",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d73d5c9a25873b824f6351737b2da049";

export default node;
