type Key = string;

export function setWithExpiry(key: Key, value: string, ttlInMin = 10): void {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttlInMin * 60000,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: Key): string | null {
  const itemStr = sessionStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}
