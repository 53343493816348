import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { COLORS, Paper } from "@stacklet/ui";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Dashboard({ title, children }: Props) {
  return (
    <Grid container>
      <Grid sx={{ my: 0 }} xs={12} item>
        <Paper sx={{ py: 2, px: 3 }}>
          <Typography
            component="h2"
            sx={{ fontWeight: 700, color: COLORS.navy.L20 }}
            variant="h1"
          >
            {title}
          </Typography>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}
