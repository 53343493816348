/**
 * @generated SignedSource<<772796e067fd447dfadedbe93f0e0ad7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComplianceDashboardQuery$variables = {
  key: string;
};
export type ComplianceDashboardQuery$data = {
  readonly dashboard: {
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"DashboardDescription_dashboard" | "OverallAnnualCost_dashboard" | "OverallCompliance_dashboard" | "OverallFindings_dashboard" | "PerSection_dashboard" | "PolicyTable_dashboard" | "Timeline_dashboard">;
  } | null | undefined;
};
export type ComplianceDashboardQuery = {
  response: ComplianceDashboardQuery$data;
  variables: ComplianceDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "documentation",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ComplianceDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallAnnualCost_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardDescription_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallFindings_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallCompliance_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PolicyTable_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Timeline_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PerSection_dashboard"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ComplianceDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "providers",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metric",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardSection",
            "kind": "LinkedField",
            "name": "flattenedSections",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPolicies",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recommendedActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceType",
                "kind": "LinkedField",
                "name": "resourceTypes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unqualifiedName",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "severity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DashboardSection",
                "kind": "LinkedField",
                "name": "topSection",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb50dc1cf8926bf9a062825e151c0075",
    "id": null,
    "metadata": {},
    "name": "ComplianceDashboardQuery",
    "operationKind": "query",
    "text": "query ComplianceDashboardQuery(\n  $key: String!\n) {\n  dashboard(key: $key) {\n    title\n    ...OverallAnnualCost_dashboard\n    ...DashboardDescription_dashboard\n    ...OverallFindings_dashboard\n    ...OverallCompliance_dashboard\n    ...PolicyTable_dashboard\n    ...Timeline_dashboard\n    ...PerSection_dashboard\n    id\n  }\n}\n\nfragment DashboardDescription_dashboard on Dashboard {\n  description\n}\n\nfragment OverallAnnualCost_dashboard on Dashboard {\n  __typename\n  key\n  providers\n}\n\nfragment OverallCompliance_dashboard on Dashboard {\n  key\n  metric\n  providers\n  title\n}\n\nfragment OverallFindings_dashboard on Dashboard {\n  key\n  providers\n  title\n}\n\nfragment PerSection_dashboard on Dashboard {\n  key\n  title\n  providers\n  flattenedSections {\n    path\n    title\n    id\n  }\n}\n\nfragment PolicyTable_dashboard on Dashboard {\n  key\n  title\n  description\n  documentation\n  flattenedSections {\n    id\n    hasPolicies\n    path\n    key\n    title\n    description\n    documentation\n    recommendedActions\n    resourceTypes {\n      unqualifiedName\n      id\n    }\n    severity\n    topSection {\n      id\n      title\n    }\n  }\n  providers\n}\n\nfragment Timeline_dashboard on Dashboard {\n  key\n  providers\n}\n"
  }
};
})();

(node as any).hash = "be4d68c0993b7a9ef71c524c5d39c340";

export default node;
