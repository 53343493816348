/**
 * @generated SignedSource<<13bc8a98f03b3f0095096e9b7c0840e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResourceOwnerConfigurationInput = {
  default?: ReadonlyArray<string> | null | undefined;
  name: string;
  orgDomain?: string | null | undefined;
  orgDomainTag?: string | null | undefined;
  scope: string;
  tags: ReadonlyArray<string>;
};
export type AccountOwnersConfigurationInput = {
  default?: ReadonlyArray<AccountOwnersInput> | null | undefined;
  name?: string | null | undefined;
  orgDomain?: string | null | undefined;
  orgDomainTag?: string | null | undefined;
  scope: string;
  tags?: ReadonlyArray<string> | null | undefined;
};
export type AccountOwnersInput = {
  account: string;
  owners: ReadonlyArray<string>;
};
export type AddOwnerProfileMutation$variables = {
  accountOwnerProfileInput: AccountOwnersConfigurationInput;
  connections: ReadonlyArray<string>;
  resourceOwnerProfileInput: ResourceOwnerConfigurationInput;
};
export type AddOwnerProfileMutation$data = {
  readonly addAccountOwnersProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly default?: ReadonlyArray<{
          readonly owners: ReadonlyArray<string>;
        }>;
        readonly tags?: ReadonlyArray<string>;
      };
    };
  } | null | undefined;
  readonly addResourceOwnerProfile: {
    readonly configuration: {
      readonly profile: string;
      readonly record: {
        readonly default?: ReadonlyArray<string> | null | undefined;
        readonly tags?: ReadonlyArray<string>;
      };
    };
  } | null | undefined;
};
export type AddOwnerProfileMutation = {
  response: AddOwnerProfileMutation$data;
  variables: AddOwnerProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountOwnerProfileInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resourceOwnerProfileInput"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "resourceOwnerProfileInput"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default",
      "storageKey": null
    }
  ],
  "type": "ResourceOwnerConfiguration",
  "abstractKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "accountOwnerProfileInput"
  }
],
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountOwners",
      "kind": "LinkedField",
      "name": "default",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owners",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountOwnersConfiguration",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOwnerProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addResourceOwnerProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addAccountOwnersProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddOwnerProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addResourceOwnerProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "configuration",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ConfigurationEdge"
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addAccountOwnersProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c997b47d88b59c51d9f5ddb359e6e90",
    "id": null,
    "metadata": {},
    "name": "AddOwnerProfileMutation",
    "operationKind": "mutation",
    "text": "mutation AddOwnerProfileMutation(\n  $resourceOwnerProfileInput: ResourceOwnerConfigurationInput!\n  $accountOwnerProfileInput: AccountOwnersConfigurationInput!\n) {\n  addResourceOwnerProfile(input: $resourceOwnerProfileInput) {\n    configuration {\n      profile\n      record {\n        __typename\n        ... on ResourceOwnerConfiguration {\n          tags\n          default\n        }\n      }\n      id\n    }\n  }\n  addAccountOwnersProfile(input: $accountOwnerProfileInput) {\n    configuration {\n      profile\n      record {\n        __typename\n        ... on AccountOwnersConfiguration {\n          tags\n          default {\n            owners\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa1a63a7f7f8986534ee8ce1fdb36a72";

export default node;
