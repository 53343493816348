/**
 * @generated SignedSource<<091b2c1ccdbb083e9158e37e3db234f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveRepositoryMutation$variables = {
  url: string;
};
export type RemoveRepositoryMutation$data = {
  readonly removeRepository: {
    readonly repository: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type RemoveRepositoryMutation = {
  response: RemoveRepositoryMutation$data;
  variables: RemoveRepositoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "removeRepository",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "removeRepository",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "771617c65486bdc96db16f9e55bde5c6",
    "id": null,
    "metadata": {},
    "name": "RemoveRepositoryMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveRepositoryMutation(\n  $url: String!\n) {\n  removeRepository(url: $url) {\n    repository {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84618521323519ed387f2fa01f1bf048";

export default node;
