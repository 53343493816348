import Grid from "@mui/material/Grid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { COLORS } from "@stacklet/ui";

import XGridCustomToolbarExport from "./XGridCustomToolbarExport";

interface Props {
  handleExport?: () => void;
  exportInProgress?: boolean;
  setColumnsButtonEl(): void;
  customInline?: React.ReactNode;
  enableFilterButton?: boolean;
}

export default function XGridCustomToolbar({
  handleExport,
  exportInProgress = false,
  setColumnsButtonEl,
  customInline,
  enableFilterButton = false,
}: Props) {
  return (
    <GridToolbarContainer
      sx={{ border: `1px solid ${COLORS.navy.L90}`, padding: 0.75, mb: 1 }}
    >
      <Grid container>
        <Grid
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          xs={12}
          item
        >
          {handleExport ? (
            <XGridCustomToolbarExport
              exportInProgress={exportInProgress}
              handleExport={handleExport}
            />
          ) : null}

          {customInline}
          {enableFilterButton ? (
            <GridToolbarFilterButton sx={{ ml: 2 }} />
          ) : null}
          <GridToolbarColumnsButton ref={setColumnsButtonEl} />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}
