import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface Props {
  message: string;
  backgroundColor?: string;
  children?: React.ReactNode;
  title?: string;
}

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(3),
}));

export default function NoData({
  children,
  backgroundColor,
  message,
  title,
}: Props): JSX.Element {
  const mainContent = (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={
        !title
          ? {
              minHeight: "100%",
              padding: 2,
              backgroundColor: backgroundColor || `action.hover`,
            }
          : {
              width: "50%",
              height: 300,
              backgroundColor: `common.white`,
              flexDirection: "column",
            }
      }
    >
      <Box mb={3}>
        <Typography align="center" color="textSecondary" variant="h3">
          {title}
        </Typography>
      </Box>
      <Typography align="center" color="textSecondary" variant="body2">
        {message}
      </Typography>
      {children ? <Box mt={6}> {children} </Box> : null}
    </Box>
  );

  return !title ? (
    <Root>{mainContent}</Root>
  ) : (
    <Root>
      <Box alignItems="center" display="flex" justifyContent="center" mt={10}>
        {mainContent}
      </Box>
    </Root>
  );
}
