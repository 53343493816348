import SaveAlt from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";

interface Props {
  handleExport: () => void;
  exportInProgress: boolean;
}

export default function XGridCustomToolbarExport({
  handleExport,
  exportInProgress,
}: Props): JSX.Element {
  return (
    <Button
      aria-label="Export"
      color="primary"
      disabled={exportInProgress}
      onClick={handleExport}
      size="small"
      startIcon={<SaveAlt />}
    >
      Export
    </Button>
  );
}
