import Box from "@mui/material/Box";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import NoData from "app/components/empty-state/NoData";
import { SourceView } from "app/components/SourceView/SourceView";

import type { ResourcesRawJson_execution$key } from "./__generated__/ResourcesRawJson_execution.graphql";

interface Props {
  execution: ResourcesRawJson_execution$key | null;
}

export default function ResourcesRawJson({ execution }: Props): JSX.Element {
  const data = useFragment(
    graphql`
      fragment ResourcesRawJson_execution on PolicyExecution
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        uuid
        resources(first: $first, last: $last, after: $after, before: $before) {
          edges {
            node {
              id
              data
              key
              resourceType
              region
            }
          }
        }
      }
    `,
    execution,
  );

  if (!data?.resources?.edges?.length) {
    return (
      <Box mt={3}>
        <NoData message={`No resources for execution ${data?.uuid}.`} />
      </Box>
    );
  }

  const resources = data.resources.edges.map((edge) => {
    return {
      id: edge.node.id,
      key: edge.node.key,
      data: edge.node.data,
      resourceType: edge.node.resourceType,
      region: edge.node.region,
    };
  });

  return <SourceView lang="json" source={JSON.stringify(resources)} />;
}
