import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Box from "@mui/material/Box";

interface Props {
  percent?: number | null;
  since?: string;
  value?: number | string;
}

export function PercentChangeMessage({
  percent,
  since = "last month",
  value,
}: Props) {
  let deltaMsg = null;
  if (percent === null || percent === undefined || !isFinite(percent)) {
    deltaMsg = "";
  } else if (value && percent > 0) {
    deltaMsg = (
      <Box alignItems="center" display="flex">
        {value} {since} (
        <ArrowUpward
          sx={{ height: ".75rem", width: ".75rem" }}
          titleAccess="increase"
        />{" "}
        +{percent}%)
      </Box>
    );
  } else if (value && percent < 0) {
    deltaMsg = (
      <Box alignItems="center" display="flex">
        {value} {since}
        (
        <ArrowDownward
          sx={{ height: ".75rem", width: ".75rem" }}
          titleAccess="decrease"
        />
        {percent}%)
      </Box>
    );
  } else if (percent === 0) {
    deltaMsg = `No change since ${since}`;
  } else if (percent > 0) {
    deltaMsg = (
      <Box alignItems="center" display="flex" gap={0.5}>
        <ArrowUpward
          sx={{ height: ".75rem", width: ".75rem" }}
          titleAccess="increase"
        />
        +{percent}% since {since}
      </Box>
    );
  } else if (percent < 0) {
    deltaMsg = (
      <Box alignItems="center" display="flex" gap={0.5}>
        <ArrowDownward
          sx={{ height: ".75rem", width: ".75rem" }}
          titleAccess="decrease"
        />
        {percent}% since {since}
      </Box>
    );
  }
  return deltaMsg;
}
