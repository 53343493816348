import { useMemo } from "react";

import { COLORS } from "@stacklet/ui";

import type { Section } from "../components/policy-table";
import type { ChartPivotRow } from "@cubejs-client/core";

type Result = ChartPivotRow[] | undefined;

type SectionResourceCount = {
  "DashboardSection.title": string;
  "ResourceCount.date.day": string;
  "ResourceCount.date": string;
  "ResourceCount.resourceCount": string;
};

type Remediation = {
  name: string;
  type: string;
  content: string;
  description: string;
};

export type RecommendedActions = {
  remediation: Remediation[];
};

/**
 * Hydrates dashboard definition rows with metrics from cubejs
 */
export default function useControlRows(
  sections?: Section[],
  results?: Array<SectionResourceCount>,
  violationsResults?: Result,
  costResults?: Result,
) {
  const rows = useMemo(() => {
    if (sections && results && violationsResults) {
      const rows = sections.map((section) => {
        const row = results.find(
          (result) => result["DashboardSection.title"] === section.title,
        );

        const resources = row
          ? Math.round(Number(row["ResourceCount.resourceCount"]))
          : null;

        const history = (violationsResults ?? []).map((row) => {
          const value = row[`${section.title},ControlSignal.distinctResources`];
          return {
            day: row.x,
            violations: !isNaN(value) ? Math.round(value) : 0,
            violationsColor: COLORS.navy.L20,
          };
        });

        const violations =
          history.length > 0 ? history[history.length - 1].violations : 0;
        const passing = resources
          ? Math.round((100 * (resources - violations)) / resources)
          : null;

        const costKey = `${section.key},ResourceCostSummaries.dailyUnblendedCost`;
        const costEntries =
          costResults?.filter((row) => {
            return costKey in row && row[costKey] !== 0;
          }) || [];
        const dailyUnblendedCost = costEntries.length
          ? costEntries[costEntries.length - 1][costKey]
          : 0;

        return {
          ...section,
          passing,
          resources,
          violations,
          history,
          dailyUnblendedCost,
        };
      });
      return rows;
    }
  }, [sections, results, violationsResults, costResults]);

  return rows;
}
