import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { COLORS } from "@stacklet/ui";

interface Props {
  children: React.ReactNode;
  heading?: boolean;
}

export function Description({ children, heading = true }: Props) {
  return (
    <Box sx={{ mb: 2 }}>
      {heading ? (
        <Typography
          sx={{
            fontWeight: 800,
            color: COLORS.navy.L30,
            textTransform: "uppercase",
          }}
          variant="xsmall"
          gutterBottom
        >
          description
        </Typography>
      ) : null}
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
}
