import CircularProgress from "@mui/material/CircularProgress";

import { BackdropFallback } from "./BackdropFallback";

export function CircularFallback(): JSX.Element {
  return (
    <BackdropFallback>
      <CircularProgress color="inherit" />
    </BackdropFallback>
  );
}
