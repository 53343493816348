import type React from "react";
import { createContext, useReducer, useContext, useState } from "react";

import Alert from "../components/ApplicationAlert";

import type { AlertStatus } from "../components/ApplicationAlert";

interface Context {
  alert: AlertStatus;
  alertDispatch: React.Dispatch<AlertStatus>;
}

interface Props {
  children: React.ReactNode;
}

const AlertContext = createContext<Context>({
  alert: { message: "", severity: "info" },
  alertDispatch: () => {
    /* tslint:disable-next-line:no-empty */
  },
});

export const AlertProvider = ({ children }: Props): JSX.Element => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const alertReducer = (state: AlertStatus, action: AlertStatus) => {
    setOpenAlert(true);
    return action;
  };

  const [alert, alertDispatch] = useReducer(alertReducer, {
    message: "",
    severity: "success",
  });
  return (
    <AlertContext.Provider value={{ alert, alertDispatch }}>
      <Alert alert={alert} openAlert={openAlert} setOpenAlert={setOpenAlert} />
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = (): Context => {
  return useContext(AlertContext);
};
