import { graphql } from "react-relay";

export const UpsertUserDataMutation = graphql`
  mutation UpsertUserDataMutation($input: UpsertUserDataInput!) {
    upsertUserData(input: $input) {
      userData {
        name
        type
        value
      }
    }
  }
`;
