import { Helmet } from "react-helmet-async";

import { useConfig } from "app/contexts";

const HOTJAR_ID = "3018584";

export default function Scripts(): JSX.Element | null {
  const config = useConfig();

  if (config?.hotjar_enabled) {
    return (
      <Helmet>
        <script>
          {` 
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`}
        </script>
      </Helmet>
    );
  }
  return null;
}
