/**
 * @generated SignedSource<<82382cef100ddd76cc42b4784c4c93e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PolicyTable_dashboard$data = {
  readonly description: string | null | undefined;
  readonly documentation: string | null | undefined;
  readonly flattenedSections: ReadonlyArray<{
    readonly description: string | null | undefined;
    readonly documentation: string | null | undefined;
    readonly hasPolicies: boolean;
    readonly id: string;
    readonly key: string;
    readonly path: ReadonlyArray<string>;
    readonly recommendedActions: string | null | undefined;
    readonly resourceTypes: ReadonlyArray<{
      readonly unqualifiedName: string;
    }>;
    readonly severity: string | null | undefined;
    readonly title: string;
    readonly topSection: {
      readonly id: string;
      readonly title: string;
    };
  }> | null | undefined;
  readonly key: string;
  readonly providers: ReadonlyArray<CloudProvider> | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "PolicyTable_dashboard";
};
export type PolicyTable_dashboard$key = {
  readonly " $data"?: PolicyTable_dashboard$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyTable_dashboard">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "documentation",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PolicyTable_dashboard",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DashboardSection",
      "kind": "LinkedField",
      "name": "flattenedSections",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPolicies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recommendedActions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceType",
          "kind": "LinkedField",
          "name": "resourceTypes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unqualifiedName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "severity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DashboardSection",
          "kind": "LinkedField",
          "name": "topSection",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};
})();

(node as any).hash = "759ae17a18236ef635d69ce6c3dca34d";

export default node;
