import type React from "react";
import { Suspense } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  children: React.ReactNode;
  size?: number;
}

export default function CircularSuspense({
  size = 40,
  children,
}: Props): JSX.Element {
  return (
    <Suspense
      fallback={
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          m="auto"
        >
          <CircularProgress color="primary" size={size} />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
}
