import { Suspense, useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import * as Sentry from "@sentry/browser";
import { useMutation, useQueryLoader } from "react-relay";

import { ExportConnectionMutation } from "app/mutations";

import QueryCSVExport, { csvExportQuery } from "./QueryCSVExport";

import type { QueryCSVExportQuery } from "./__generated__/QueryCSVExportQuery.graphql";
import type {
  ExportConnectionInput,
  ExportConnectionMutation as ExportConnectionMutationType,
} from "app/mutations/__generated__/ExportConnectionMutation.graphql";

interface Props {
  nodeId?: string | null;
  field: string;
  columns: { name: string; path: string; subpath?: string }[];
  markComplete?: () => void;
  rowCount: number;
  params?: ExportConnectionInput["params"];
  extraPaths?: ExportConnectionInput["extraPaths"];
  exportInProgress: boolean;
}

export default function ExportCsv({
  nodeId,
  field,
  columns,
  exportInProgress,
  markComplete = () => {
    /* do nothing */
  },
  rowCount,
  params,
  extraPaths,
}: Props) {
  const [exportId, setExportId] = useState<string>("");
  const [queryRef, loadQuery, disposeQuery] =
    useQueryLoader<QueryCSVExportQuery>(csvExportQuery);
  const [csvExport] = useMutation<ExportConnectionMutationType>(
    ExportConnectionMutation,
  );

  useEffect(() => {
    if (exportInProgress && exportId) {
      loadQuery({ id: exportId }, { fetchPolicy: "network-only" });
    }
  }, [loadQuery, exportId, exportInProgress]);

  const refetch = useCallback(() => {
    loadQuery({ id: exportId }, { fetchPolicy: "network-only" });
  }, [loadQuery, exportId]);

  useEffect(() => {
    const disposable =
      exportInProgress &&
      csvExport({
        variables: {
          input: {
            node: nodeId,
            field,
            columns,
            format: "CSV",
            params,
            extraPaths,
          },
        },
        onCompleted(res, err) {
          if (err) Sentry.captureException(err);

          if (res.exportConnection?.export?.id) {
            setExportId(res.exportConnection.export.id);
          }
        },
        onError: (err) => {
          Sentry.captureException(err);
        },
      });
    return () => {
      if (disposable) {
        disposable.dispose();
      }
      // Dispose the results query to avoid getting cached results from a
      // previous export even after the query params have changed.
      disposeQuery();
    };
  }, [
    columns,
    csvExport,
    field,
    nodeId,
    setExportId,
    params,
    extraPaths,
    exportInProgress,
    disposeQuery,
  ]);

  return (
    <>
      {exportInProgress && queryRef ? (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ fontSize: "10px" }}>File export in progress.</Box>
          <Suspense
            fallback={<LinearProgress value={0} variant="determinate" />}
          >
            <QueryCSVExport
              markComplete={markComplete}
              queryRef={queryRef}
              refetch={refetch}
              rowCount={rowCount}
              setExportId={setExportId}
            />
          </Suspense>
        </Box>
      ) : null}
    </>
  );
}
