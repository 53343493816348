import { useEffect, useState } from "react";

import * as Sentry from "@sentry/browser";

import getConfigs from "app/utils/configs";

const pageCache = new Map();

/* Clear one or more help pages from the cache. This will cause the page
 * content to be re-downloaded the next time the page is requested.
 */
export function clearPageCache(pageNames: string[] = []): void {
  if (!pageNames.length) {
    pageCache.clear();
  } else {
    pageNames.map((pageName) => pageCache.delete(pageName));
  }
}

export function useHelpContent(pageNames: string[]): [boolean, string] {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getHelpPage(pageName: string): Promise<string> {
      if (pageCache.has(pageName)) {
        return pageCache.get(pageName) || "";
      }

      const errMsg = `<span>Couldn't load help content for page '${pageName}'.</span>`;
      const configs = await getConfigs();
      if (configs.docs?.docs_url) {
        const pageUrl = `${configs.docs.docs_url}/help/${pageName}/`;
        try {
          setIsLoading(true);
          const response = await fetch(pageUrl, { credentials: "include" });
          if (response.ok) {
            const responseText = await response.text();
            pageCache.set(pageName, responseText);
            return responseText;
          } else {
            throw new Error(
              `Fetch failed for ${pageUrl}, response.status = ${response.status}`,
            );
          }
        } catch (error) {
          Sentry.captureException(error);
          return errMsg;
        }
      }
      return errMsg;
    }

    async function getContent() {
      await Promise.all(pageNames.map(getHelpPage)).then((pages) => {
        setContent(pages.join("<hr>"));
      });
      setIsLoading(false);
    }

    getContent();
  });

  return [isLoading, content];
}
