import { Suspense } from "react";

import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { graphql } from "react-relay";
import { type PreloadedQuery, usePreloadedQuery } from "react-relay";

import { RectangleSkeleton } from "app/components/skeletons";

import { Remediations } from "../../details/Remediations";
import { TopAccounts } from "../TopAccounts";
import { SectionModalDetails } from "./SectionModalDetails";
import { SectionModalResources } from "./SectionModalResources";

import type { SectionModalQuery } from "./__generated__/SectionModalQuery.graphql";

export const sectionModalQuery = graphql`
  query SectionModalQuery($key: String, $sectionKey: String) {
    dashboard(key: $key) {
      section(sectionKey: $sectionKey) {
        ...Remediations_recommendedActions
        ...SectionModalDetails_section
        ...TopAccounts_topAccounts
      }
      ...SectionModalResources_dashboard
    }
  }
`;

interface Props {
  queryRef: PreloadedQuery<SectionModalQuery, Record<string, unknown>>;
  onClose(): void;
  open: boolean;
}

export function SectionModal({ queryRef, onClose, open }: Props) {
  const data = usePreloadedQuery<SectionModalQuery>(
    sectionModalQuery,
    queryRef,
  );

  if (!data.dashboard?.section) {
    return null;
  }

  return (
    <Dialog
      PaperProps={{ sx: { pointerEvents: "auto" } }}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      sx={{
        pointerEvents: "none",
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          justifyContent: "flex-end",
        },
        "& .MuiDialog-container .MuiDialog-paper": {
          boxShadow: "0px 12px 56px 0px rgba(33, 43, 54, 0.10)",
        },
        mt: 7,
        mr: 4,
      }}
      disableEnforceFocus
      fullWidth
      hideBackdrop
    >
      <Suspense fallback={<RectangleSkeleton height="85vh" />}>
        <Box sx={{ padding: 2 }}>
          <Stack direction="column" spacing={2}>
            <SectionModalDetails
              dashboardSection={data.dashboard.section}
              onClose={onClose}
            />
            <Divider />
            <Box>
              <Typography sx={{ mb: 1 }} variant="h4">
                Manual remediation
              </Typography>
              <Remediations dashboardSection={data.dashboard.section} />
            </Box>
            <TopAccounts dashboardSection={data.dashboard.section} />
            <SectionModalResources dashboard={data.dashboard} />
          </Stack>
        </Box>
      </Suspense>
    </Dialog>
  );
}
