import { createContext } from "react";
import type React from "react";

import type { AWSOrganisationInput } from "app/mutations/__generated__/AddAWSOrganisationMutation.graphql";

interface OnboardingContext {
  orgDetails: AWSOrganisationInput | null;
  setOrgDetails: React.Dispatch<
    React.SetStateAction<AWSOrganisationInput | null>
  >;
}

const Context = createContext<OnboardingContext>({
  orgDetails: null,
  setOrgDetails: () => {
    // do nothing
  },
});

export default Context;
