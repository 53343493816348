import Box from "@mui/material/Box";
import { useGridRootProps, useGridApiContext } from "@mui/x-data-grid-pro";
import pluralize from "pluralize";

declare module "@mui/x-data-grid-pro" {
  interface FooterPropsOverrides {
    totalRows: number;
  }
}

interface Props {
  totalRows: number;
}

export default function CustomFooterComponent({ totalRows }: Props) {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;

  const rootProps = useGridRootProps();
  const selectedRows = state.rowSelection.length ?? 0;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      height="52px"
      justifyContent="space-between"
    >
      <Box>
        <Box hidden={!rootProps.checkboxSelection || selectedRows === 0}>
          {`${selectedRows} ${pluralize("row", selectedRows)} selected`}
        </Box>
      </Box>
      <Box>
        Total rows: {state.rows.totalRowCount}/{totalRows}
      </Box>
    </Box>
  );
}
