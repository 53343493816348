import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import SeverityCriticalLogo from "assets/icons/severity/critical.svg?react";
import SeverityHighLogo from "assets/icons/severity/high.svg?react";
import SeverityLowLogo from "assets/icons/severity/low.svg?react";
import SeverityMediumLogo from "assets/icons/severity/medium.svg?react";
import SeverityUnknownLogo from "assets/icons/severity/unknown.svg?react";
import { nanoid } from "nanoid";

interface Props {
  severityLevel: SeverityLevel;
}

export type SeverityLevel = "low" | "medium" | "high" | "critical" | null;

export default function SeverityIcon({ severityLevel }: Props): JSX.Element {
  let SeverityLogo;

  switch (severityLevel) {
    case "low":
      SeverityLogo = SeverityLowLogo;
      break;
    case "medium":
      SeverityLogo = SeverityMediumLogo;
      break;
    case "high":
      SeverityLogo = SeverityHighLogo;
      break;
    case "critical":
      SeverityLogo = SeverityCriticalLogo;
      break;
    default:
      SeverityLogo = SeverityUnknownLogo;
  }

  const id = `severity-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        height: "25px",
        width: "25px",
      }}
    >
      <Tooltip
        data-testid="severity-tooltip"
        id={id}
        title={severityLevel || "unknown"}
      >
        <Box alignItems="center" display="flex">
          <SeverityLogo aria-labelledby={id} />
        </Box>
      </Tooltip>
    </Box>
  );
}
