import Box from "@mui/material/Box";

interface Props {
  children: React.ReactNode;
}

export default function DetailsMetaData({ children }: Props): JSX.Element {
  return (
    <Box
      data-testid="metadata"
      sx={{
        flexGrow: 1,
        px: 4,
        py: 2,
        backgroundColor: (theme) => theme.palette.common.white,
      }}
    >
      {children}
    </Box>
  );
}
