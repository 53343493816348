import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { GridOverlay } from "@mui/x-data-grid-pro";

export default function CustomLoadingOverlay(): JSX.Element {
  return (
    <GridOverlay>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <LinearProgress color="secondary" />
      </Box>
    </GridOverlay>
  );
}
