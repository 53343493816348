/**
 * @generated SignedSource<<788e6f4346eac2a5e63cebf0d656c0ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type PolicyComplianceAutocompleteRefetchQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
};
export type PolicyComplianceAutocompleteRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PolicyComplianceAutocomplete_compliance">;
};
export type PolicyComplianceAutocompleteRefetchQuery = {
  response: PolicyComplianceAutocompleteRefetchQuery$data;
  variables: PolicyComplianceAutocompleteRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PolicyComplianceAutocompleteRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PolicyComplianceAutocomplete_compliance"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PolicyComplianceAutocompleteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filterElement",
            "variableName": "filterElement"
          },
          (v1/*: any*/)
        ],
        "concreteType": "PolicyComplianceValueConnection",
        "kind": "LinkedField",
        "name": "policyComplianceValues",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyComplianceValueEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyComplianceValue",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "compliance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "306b8aad3006a2241b0c58f07f578b1a",
    "id": null,
    "metadata": {},
    "name": "PolicyComplianceAutocompleteRefetchQuery",
    "operationKind": "query",
    "text": "query PolicyComplianceAutocompleteRefetchQuery(\n  $filterElement: FilterElementInput\n  $first: Int = 10\n) {\n  ...PolicyComplianceAutocomplete_compliance_3ASum4\n}\n\nfragment PolicyComplianceAutocomplete_compliance_3ASum4 on Query {\n  policyComplianceValues(filterElement: $filterElement, first: $first) {\n    edges {\n      node {\n        compliance\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1145ef70332c0b3c5e31795e2d85c870";

export default node;
