import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  topMargin?: number;
  size?: "small" | "medium" | "large";
}

export default function TableSkeleton({ topMargin, size = "medium" }: Props) {
  let height = "";

  switch (size) {
    case "small":
      height = "200px";
      break;

    case "medium":
      height = "500px";
      break;

    case "large":
      height = "1000px";
      break;
  }

  return (
    <Box
      sx={{
        height: "80%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        mt: topMargin || 5,
      }}
    >
      <Box
        role="progressbar"
        sx={{
          display: "flex",
          height: "30px",
          width: "100%",
        }}
      >
        {Array.from([1, 2], (_) => (
          <Skeleton
            animation="wave"
            height="25px"
            key={_}
            role="progressbar"
            sx={{ mr: 1 }}
            variant="rectangular"
            width="80px"
          />
        ))}
      </Box>
      <Skeleton
        animation="wave"
        height={height}
        role="progressbar"
        sx={{ mt: 1 }}
        variant="rectangular"
        width="100%"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          height: "30px",
          width: "100%",
        }}
      >
        <Skeleton
          animation="wave"
          height="28px"
          role="progressbar"
          sx={{ mt: 1 }}
          variant="rectangular"
          width="144px"
        />
      </Box>
    </Box>
  );
}
