/**
 * @generated SignedSource<<709c429980cd0e427edbee4352facfe9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RevalidateAccount_account$data = {
  readonly key: string;
  readonly provider: CloudProvider;
  readonly " $fragmentType": "RevalidateAccount_account";
};
export type RevalidateAccount_account$key = {
  readonly " $data"?: RevalidateAccount_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"RevalidateAccount_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevalidateAccount_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "d82414ed28112669148745fca8f08045";

export default node;
