import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";

import { Title } from "./DetailGridValue";

interface Props {
  children: React.ReactNode;
  title: string;
}

export default function ExpandableDropdown({
  children,
  title,
}: Props): JSX.Element {
  return (
    <Accordion
      sx={{
        borderBottom: "1px solid #fff",
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          alignItems: "flex-start",
          backgroundColor: "#fff",
          borderBottom: "1px solid #fff",
          minHeight: 0,
          padding: 0,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
        }}
      >
        <Title>{title}</Title>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, position: "fixed", zIndex: 1 }}>
        <Box>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
}
