/**
 * @generated SignedSource<<44f566bba3d144040ad79435e2aa2b2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveProfileMutation$variables = {
  name: string;
  scope: string;
};
export type RemoveProfileMutation$data = {
  readonly removeProfile: string | null | undefined;
};
export type RemoveProfileMutation = {
  response: RemoveProfileMutation$data;
  variables: RemoveProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scope"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "removeProfile",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveProfileMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveProfileMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "deleteRecord",
        "key": "",
        "kind": "ScalarHandle",
        "name": "removeProfile"
      }
    ]
  },
  "params": {
    "cacheID": "f38c900838343f33726f386a4a356165",
    "id": null,
    "metadata": {},
    "name": "RemoveProfileMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveProfileMutation(\n  $name: String!\n  $scope: String!\n) {\n  removeProfile(name: $name, scope: $scope)\n}\n"
  }
};
})();

(node as any).hash = "cf98b4cb5b092b0b3d975069dc33c762";

export default node;
