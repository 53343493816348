/**
 * @generated SignedSource<<150bedb2a69d35cfd8511a54cd0478b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type AccountGroupDetailsRootQuery$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
  uuid: string;
};
export type AccountGroupDetailsRootQuery$data = {
  readonly accountGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountGroupDetails_accountMappings">;
  } | null | undefined;
};
export type AccountGroupDetailsRootQuery = {
  response: AccountGroupDetailsRootQuery$data;
  variables: AccountGroupDetailsRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterElement"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v6 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountGroupDetailsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroup",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "FragmentSpread",
            "name": "AccountGroupDetails_accountMappings"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountGroupDetailsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "AccountGroupMappingConnection",
            "kind": "LinkedField",
            "name": "accountMappings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroupMappingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountGroupMapping",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status_message",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [
              "filterElement"
            ],
            "handle": "connection",
            "key": "AccountGroupDetails_accountMappings",
            "kind": "LinkedHandle",
            "name": "accountMappings"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dynamicFilter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "system",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regions",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f78631e09a1fd70bf34b504869c7098",
    "id": null,
    "metadata": {},
    "name": "AccountGroupDetailsRootQuery",
    "operationKind": "query",
    "text": "query AccountGroupDetailsRootQuery(\n  $uuid: String!\n  $first: Int\n  $last: Int\n  $after: String\n  $before: String\n  $filterElement: FilterElementInput\n) {\n  accountGroup(uuid: $uuid) {\n    ...AccountGroupDetails_accountMappings_4EDJP0\n    id\n  }\n}\n\nfragment AccountGroupDetails_accountMappings_4EDJP0 on AccountGroup {\n  accountMappings(first: $first, last: $last, after: $after, before: $before, filterElement: $filterElement) {\n    edges {\n      node {\n        id\n        account {\n          key\n          name\n          provider\n          status\n          status_message\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      total\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n  dynamicFilter\n  uuid\n  name\n  provider\n  description\n  system\n  regions\n  id\n}\n"
  }
};
})();

(node as any).hash = "2fa2d8b938f0bef7927e26236a5a3500";

export default node;
