import Box from "@mui/material/Box";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "app/components/fallbacks";

interface Props {
  children: React.ReactNode;
  name?: string;
}

export default function ChartErrorBoundary({
  name = "chart",
  children,
}: Props) {
  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => (
        <Box sx={{ width: "100%" }}>
          <ErrorFallback
            heading={false}
            height={100}
            message={`Error displaying ${name}`}
            resetErrorBoundary={resetErrorBoundary}
            tryAgain
          />
        </Box>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
