import { styled } from "@mui/material/styles";
import MuiTab from "@mui/material/Tab";

export const Tab = styled(MuiTab)(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  fontSize: theme.typography.h6.fontSize,
  marginRight: theme.spacing(2),
  "&.Mui-selected": {
    color: "inherit",
  },
}));
