import Typography from "@mui/material/Typography";
import { COLORS } from "@stacklet/ui";

import type { TypographyProps } from "@mui/system";

interface Props extends TypographyProps {
  amount: number;
  currency?: string;
  color?: string;
  compact?: boolean;
  decimals?: boolean;
}

export function Currency({
  amount,
  currency = "USD",
  color = COLORS.orange.base,
  compact = false,
  decimals = true,
  ...rest
}: Props) {
  const formatter = new Intl.NumberFormat("en-US", {
    compactDisplay: compact ? "short" : "long",
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
    currency,
    notation: compact ? "compact" : "standard",
    style: "currency",
  });

  return (
    <Typography color={color} variant="inherit" {...rest}>
      {formatter.format(amount)}
    </Typography>
  );
}
