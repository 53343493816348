import { useState } from "react";

import Box from "@mui/material/Box";
import * as Sentry from "@sentry/browser";
import { graphql } from "react-relay";
import { usePaginationFragment } from "react-relay/hooks";

import { formatColumns } from "app/components/column-mapping/formatColumns";
import { resourceColumnMapping } from "app/components/column-mapping/resourceColumnMapping";
import ExportCsv from "app/components/csv-export/ExportCsv";
import NoData from "app/components/empty-state/NoData";
import Table from "app/components/table/XGridTable";
import { useLoadNext } from "app/hooks";

import type { ResourcesTable_execution$key } from "./__generated__/ResourcesTable_execution.graphql";
import type { ResourcesTableRefetchQuery } from "./__generated__/ResourcesTableRefetchQuery.graphql";

interface Props {
  execution: ResourcesTable_execution$key | null;
}

export default function ResourcesTable({ execution }: Props): JSX.Element {
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const resourcesFragmentQuery = graphql`
    fragment ResourcesTable_execution on PolicyExecution
    @argumentDefinitions(
      first: { type: "Int" }
      after: { type: "String" }
      last: { type: "Int" }
      before: { type: "String" }
    )
    @refetchable(queryName: "ResourcesTableRefetchQuery") {
      id
      uuid
      policy {
        resourceType
      }
      resources(first: $first, last: $last, after: $after, before: $before)
        @connection(key: "ResourcesTable_resources") {
        edges {
          node {
            data
            key
            resourceType
          }
        }
        pageInfo {
          total
        }
      }
    }
  `;

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    ResourcesTableRefetchQuery,
    ResourcesTable_execution$key
  >(resourcesFragmentQuery, execution);

  const loadMore = useLoadNext(hasNext, loadNext);

  if (!data || !data.resources || data.resources.edges.length === 0) {
    return (
      <Box mt={3}>
        <NoData message={`No resources for execution ${data?.uuid}.`} />
      </Box>
    );
  }

  const rows = (data.resources.edges ?? []).map((edge) => {
    let data;
    try {
      data = JSON.parse(edge.node.data);
    } catch (err) {
      Sentry.captureException(err);
    }

    return {
      ...data,
      key: edge.node.key,
    };
  });

  const resourceMapping = resourceColumnMapping[data.policy.resourceType];

  if (!resourceMapping) {
    return (
      <Box mt={3}>
        <NoData
          message={`Support for displaying ${data.policy.resourceType} as a table is coming soon, you can view the JSON tab still.`}
        />
      </Box>
    );
  }

  const formattedColumns = formatColumns(resourceMapping.columns);

  const columnsCsvExport = (resourceMapping.columns ?? []).map((column) => {
    return {
      name: column.field,
      path: "data",
      subpath: column.field,
    };
  });

  const rowCount = data.resources.pageInfo.total || rows.length;

  return (
    <Box height={"80%"}>
      <ExportCsv
        columns={columnsCsvExport}
        exportInProgress={exportInProgress}
        field="resources"
        markComplete={() => setExportInProgress(false)}
        nodeId={data.id}
        rowCount={rowCount}
      />
      <Table
        columns={formattedColumns}
        getRowId={(row) => row.key}
        handleExport={() => setExportInProgress(true)}
        loading={isLoadingNext}
        onRowsScrollEnd={loadMore}
        rowCount={rowCount}
        rows={rows}
        tableId="overview-policies-details-resources"
        hideFooterPagination
      />
    </Box>
  );
}
