import "regenerator-runtime/runtime";
import * as Sentry from "@sentry/browser";
import {
  RelayNetworkLayer,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
  perfMiddleware,
  retryMiddleware,
  authMiddleware,
} from "react-relay-network-modern";
import { Environment, RecordSource, Store } from "relay-runtime";

import { getAuthToken } from "app/utils/auth";
import getConfigs from "app/utils/configs";

const __DEV__ =
  import.meta.env.NODE_ENV !== "production" && import.meta.env.VITE_GQL_DEBUG;

const network = new RelayNetworkLayer(
  [
    __DEV__ ? loggerMiddleware() : null,
    __DEV__ ? perfMiddleware() : null,
    urlMiddleware({
      url: async (): Promise<string> => {
        const config = await getConfigs();
        return config.deployment.gql_uri;
      },
    }),
    retryMiddleware({
      beforeRetry: ({ abort, req }) => {
        // resource matches can experience query perform issues
        // so we avoid retrying.
        if (req.getID().includes("ResourceMatches")) {
          abort("Aborting retry for resource matches");
        }
      },
      fetchTimeout: 15000,
      retryDelays: [3200, 6400, 12800, 25600, 51200],
      statusCodes: [500, 502, 503, 504],
    }),
    authMiddleware({
      token: () => getAuthToken(),
    }),
    errorMiddleware({
      logger: (message: string) => {
        Sentry.captureMessage(message);
      },
    }),
    // custom middleware
    (next) => async (req) => {
      const config = await getConfigs();

      if (config.deployment.headers) {
        try {
          req.fetchOpts.headers = {
            ...req.fetchOpts.headers,
            ...(config.deployment.headers || {}),
          };
        } catch {
          console.error(
            "Custom headers appear to be malformed, please check deployment.json",
          );
        }
      }
      const res = await next(req);
      return res;
    },
  ],
  { noThrow: true },
);

const store = new Store(new RecordSource());

const environment = new Environment({ network, store });

export default environment;
