import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { StatCard } from "@stacklet/ui";

import { EOM_DATE_STR } from "app/utils/date";

import { PercentChangeMessage } from "../../PercentChange";

import type { Query, ResultSet } from "@cubejs-client/core";
import type { $TSFixMe } from "App";

function getCompliancePct(resultSet: ResultSet | null): number | null {
  if (!resultSet) {
    return null;
  }
  const [total, violations] = resultSet.decompose().map((r) => r.rawData());
  const totalResources = total?.length
    ? total[0]["ResourceCount.resourceCount"]
    : 0;
  const matchedResources = violations?.length
    ? violations[0]["ControlSignal.distinctResources"]
    : 0;

  if (totalResources === 0 && matchedResources === 0) {
    return null;
  }

  return Math.round(
    (100 * (totalResources - matchedResources)) / totalResources,
  );
}

function buildQuery(
  dateRange: string | [string, string],
  dashboardKey: string,
  provider: string | null,
  sectionKey?: string,
): Query[] {
  return [
    {
      measures: ["ResourceCount.resourceCount"],
      timeDimensions: [
        {
          dimension: "ResourceCount.date",
          granularity: "day",
          dateRange,
        },
      ],
      filters: [
        {
          member: "Dashboard.key",
          operator: "equals",
          values: [dashboardKey],
        },
        ...(sectionKey
          ? [
              {
                member: "DashboardSection.key",
                operator: "equals",
                values: [sectionKey],
              },
            ]
          : []),
        ...(provider
          ? [
              {
                member: "Account.provider",
                operator: "equals",
                values: [provider],
              },
            ]
          : ([] as $TSFixMe)),
      ],
    },
    {
      measures: ["ControlSignal.distinctResources"],
      timeDimensions: [
        {
          dimension: "ControlSignal.date",
          granularity: "day",
          dateRange,
        },
      ],
      filters: [
        {
          member: "Dashboard.key",
          operator: "equals",
          values: [dashboardKey],
        },
        ...(sectionKey
          ? [
              {
                member: "DashboardSection.key",
                operator: "equals",
                values: [sectionKey],
              },
            ]
          : []),
        ...(provider
          ? [
              {
                member: "Account.provider",
                operator: "equals",
                values: [provider],
              },
            ]
          : ([] as $TSFixMe)),
      ],
    },
  ];
}

type Props = {
  dashboardKey: string;
  title: string;
  metric?: string | null;
  provider?: string | null;
  sectionKey?: string;
};

export function Compliance({
  dashboardKey,
  sectionKey,
  metric,
  provider = null,
  title,
}: Props) {
  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const queryCurr = useCubeQuery(
    buildQuery("Yesterday", dashboardKey, provider, sectionKey),
  );

  const queryPrev = useCubeQuery(
    buildQuery(
      [EOM_DATE_STR, EOM_DATE_STR],
      dashboardKey,
      provider,
      sectionKey,
    ),
  );

  if (queryCurr?.isLoading || queryPrev?.isLoading) {
    return (
      <Skeleton
        animation="wave"
        height="100px"
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  if (queryCurr.error) {
    Sentry.captureException(queryCurr.error);
    console.error(queryCurr.error.toString());
    throw queryCurr.error;
  }

  if (queryPrev.error) {
    Sentry.captureException(queryPrev.error);
    console.error(queryPrev.error.toString());
    throw queryPrev.error;
  }

  const currPctC = getCompliancePct(queryCurr.resultSet);
  const prevPctC = getCompliancePct(queryPrev.resultSet);

  if (currPctC === null || isNaN(currPctC) || !isFinite(currPctC) || !metric) {
    return <Typography>{`${metric} data not available`}</Typography>;
  }

  const percentChanged = currPctC && prevPctC ? currPctC - prevPctC : null;

  return (
    <StatCard
      description={`Percentage of resources that are ${title} compliant `}
      message={<PercentChangeMessage percent={percentChanged} />}
      title={metric}
    >
      {currPctC}%
    </StatCard>
  );
}
