import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import defaultLogo from "assets/icons/fi_layers.svg?react";
import adminIcon from "assets/icons/sidebar/admin.svg?react";
import notificationsIcon from "assets/icons/sidebar/bell.svg?react";
import cloudOperationsIcon from "assets/icons/sidebar/cloud-operations.svg?react";
import homeIcon from "assets/icons/sidebar/home.svg?react";
import settingsIcon from "assets/icons/sidebar/settings.svg?react";
import toolsIcon from "assets/icons/sidebar/tools.svg?react";
import { nanoid } from "nanoid";

interface Props {
  title: string;
}

export function SidebarIcon({ title }: Props) {
  let SidebarLogo;

  switch (title) {
    case "overview":
      SidebarLogo = homeIcon;
      break;
    case "cloudops":
      SidebarLogo = cloudOperationsIcon;
      break;
    case "admin":
      SidebarLogo = adminIcon;
      break;
    case "notifications":
      SidebarLogo = notificationsIcon;
      break;
    case "stacklet-tools":
      SidebarLogo = toolsIcon;
      break;
    case "settings":
      SidebarLogo = settingsIcon;
      break;

    default:
      SidebarLogo = defaultLogo;
  }

  const id = `logo-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        height: "16px",
        width: "16px",
      }}
    >
      <Tooltip id={id} title={title}>
        <span>
          <SidebarLogo aria-labelledby={id} />
        </span>
      </Tooltip>
    </Box>
  );
}
