/**
 * @generated SignedSource<<7e5c96825466f6a90a8369881bc2e99e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SlackConfigurationInput = {
  name: string;
  scope: string;
  token?: string | null | undefined;
  userFields?: ReadonlyArray<string> | null | undefined;
  webhooks?: ReadonlyArray<SlackWebhookInput | null | undefined> | null | undefined;
};
export type SlackWebhookInput = {
  name: string;
  url: string;
};
export type AddSlackProfileMutation$variables = {
  connections: ReadonlyArray<string>;
  input: SlackConfigurationInput;
};
export type AddSlackProfileMutation$data = {
  readonly addSlackProfile: {
    readonly configuration: {
      readonly id: string;
      readonly profile: string;
      readonly record: {
        readonly token?: string | null | undefined;
        readonly webhooks?: ReadonlyArray<{
          readonly name: string;
          readonly url: string;
        }> | null | undefined;
      };
      readonly scope: string;
    };
  } | null | undefined;
};
export type AddSlackProfileMutation = {
  response: AddSlackProfileMutation$data;
  variables: AddSlackProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SlackWebhook",
      "kind": "LinkedField",
      "name": "webhooks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SlackConfiguration",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSlackProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addSlackProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSlackProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigurationPayload",
        "kind": "LinkedField",
        "name": "addSlackProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "configuration",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ConfigurationEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e67275b0546bd53adc8105fe48cf8152",
    "id": null,
    "metadata": {},
    "name": "AddSlackProfileMutation",
    "operationKind": "mutation",
    "text": "mutation AddSlackProfileMutation(\n  $input: SlackConfigurationInput!\n) {\n  addSlackProfile(input: $input) {\n    configuration {\n      id\n      scope\n      profile\n      record {\n        __typename\n        ... on SlackConfiguration {\n          token\n          webhooks {\n            name\n            url\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0e3213ab562ae45955c4ff3b3a3cf33";

export default node;
