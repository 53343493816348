import { TabPanel, Tabs, ToggleTab, ToggleTabsList } from "@stacklet/ui";

import { Dashboard } from "app/views/dashboards/components";

import OverviewPage from "../OverviewPage";
import { ActiveResourceCount } from "./ActiveResourceCount";
import { Bindings } from "./Bindings";
import { PolicyExecutionsCount } from "./PolicyExecutionsCount";

export function Activity() {
  return (
    <OverviewPage title="Activity">
      <Dashboard title="Stacklet Activity">
        <section>
          <Tabs defaultValue={1}>
            <ToggleTabsList>
              <ToggleTab value={0}>Last hour</ToggleTab>
              <ToggleTab value={1}>Last week</ToggleTab>
              <ToggleTab value={2}>Last month</ToggleTab>
            </ToggleTabsList>

            <TabPanel value={0}>
              <ActiveResourceCount granularity="minute" />
              <PolicyExecutionsCount granularity="minute" />
              <Bindings granularity="hour" />
            </TabPanel>
            <TabPanel value={1}>
              <ActiveResourceCount granularity="hour" />
              <PolicyExecutionsCount granularity="hour" />
              <Bindings granularity="week" />
            </TabPanel>
            <TabPanel value={2}>
              <ActiveResourceCount granularity="day" />
              <PolicyExecutionsCount granularity="day" />
              <Bindings granularity="month" />
            </TabPanel>
          </Tabs>
        </section>
      </Dashboard>
    </OverviewPage>
  );
}
