import { useContext } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";

import { useAnalytics } from "app/contexts";
import { HelpContext } from "app/contexts/HelpContext";

interface HelpButtonProps {
  pageNames: string[];
}

export default function HelpButton({
  pageNames,
}: HelpButtonProps): JSX.Element {
  const analytics = useAnalytics();
  const helpContext = useContext(HelpContext);
  const showHelp = (pageNames: string[]) => {
    helpContext.setPageNames(pageNames);
    helpContext.expand();
    analytics.track("Help Opened", { pages: pageNames });
  };

  return (
    <IconButton aria-label="open help" onClick={() => showHelp(pageNames)}>
      <HelpOutlineIcon />
    </IconButton>
  );
}
