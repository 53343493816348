/**
 * @generated SignedSource<<47e2da631ce6506425be7f2f0ead4fe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveAccountGroupMutation$variables = {
  uuid: string;
};
export type RemoveAccountGroupMutation$data = {
  readonly removeAccountGroup: {
    readonly group: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type RemoveAccountGroupMutation = {
  response: RemoveAccountGroupMutation$data;
  variables: RemoveAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "removeAccountGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroup",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "removeAccountGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroup",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a9700e738905bbe8331746144b05126",
    "id": null,
    "metadata": {},
    "name": "RemoveAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAccountGroupMutation(\n  $uuid: String!\n) {\n  removeAccountGroup(uuid: $uuid) {\n    group {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6271aaeee90458c92de08aeebd9a3b20";

export default node;
