import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import styled from "@mui/system/styled";
import { Paper } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useFragment } from "react-relay";

import { DailyCost } from "app/components/DailyCost";

import type { TopAccounts_topAccounts$key } from "./__generated__/TopAccounts_topAccounts.graphql";

const TableRow = styled(MuiTableRow)({
  borderRadius: 0,
  border: "1px solid rgba(224, 224, 224, 1)",
});

interface Props {
  dashboardSection: TopAccounts_topAccounts$key;
}

export function TopAccounts({ dashboardSection }: Props) {
  const data = useFragment(
    graphql`
      fragment TopAccounts_topAccounts on DashboardSection {
        topAccounts(number: 5) {
          account {
            id
            name
          }
          cost {
            currency
            multiplier
            totalCost
            totalDays
          }
        }
      }
    `,
    dashboardSection,
  );

  const hasCost = data.topAccounts.some(
    (topAccount) => topAccount.cost.totalCost !== 0,
  );

  if (data.topAccounts.length === 0 || !hasCost) {
    return null;
  }

  return (
    <Box>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ mb: 1 }} variant="h4">
        Top 5 accounts
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="top 5 accounts" size="small">
          <TableHead>
            <MuiTableRow>
              <TableCell>
                <Typography sx={{ textTransform: "uppercase" }}>
                  Name
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={{ textTransform: "uppercase" }}>
                  Daily Cost ($)
                </Typography>
              </TableCell>
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {data.topAccounts.map((topAccount) => (
              <TableRow key={topAccount.account.id}>
                <TableCell>
                  <Typography variant="body1">
                    {topAccount.account.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <DailyCost
                    currency={topAccount.cost.currency}
                    multiplier={topAccount.cost.multiplier}
                    totalCost={topAccount.cost.totalCost}
                    totalDays={topAccount.cost.totalDays}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
