/**
 * @generated SignedSource<<38de240d783cc9be2814492bd35c2491>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useProblemsAlert_problems$data = {
  readonly problems: ReadonlyArray<{
    readonly message: string;
  }>;
  readonly " $fragmentType": "useProblemsAlert_problems";
};
export type useProblemsAlert_problems$key = {
  readonly " $data"?: useProblemsAlert_problems$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProblemsAlert_problems">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useProblemsAlert_problems",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "problems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Problems",
  "abstractKey": "__isProblems"
};

(node as any).hash = "49163a825839d8ba2294c5aa8dbc2b98";

export default node;
