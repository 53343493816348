import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { COLORS, Tooltip } from "@stacklet/ui";

import { BindingStatusEnum } from "./types";

import type { BindingProgress } from "./types";

interface Props {
  progress: BindingProgress | null;
  force?: boolean;
}

export function BindingStatusBar({ progress, force = false }: Props) {
  if (!progress || !(progress.status == BindingStatusEnum.RUNNING || force)) {
    return null;
  }

  return (
    <Tooltip
      placement="right"
      title={
        <Stack spacing={0.5}>
          <Typography>
            {Math.trunc(progress.progressPercent)}% complete
          </Typography>
          <Typography>
            {progress.savedExecutions} / {progress.expectedExecutions}{" "}
            executions
          </Typography>
          <Typography>{progress.errorCount || 0} errors</Typography>
          <Typography>{progress.elapsedTime} elapsed</Typography>
        </Stack>
      }
    >
      <LinearProgress
        sx={{
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: COLORS.cobalt.D30,
          },
          "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: COLORS.red.D20,
          },
          "& .MuiLinearProgress-dashedColorPrimary": {
            backgroundColor: COLORS.navy.L90,
            backgroundImage: "none",
            animation: "none",
          },
        }}
        value={progress.progressPercent - progress.errorPercent}
        valueBuffer={progress.progressPercent}
        variant="buffer"
      />
    </Tooltip>
  );
}
