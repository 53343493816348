/**
 * @generated SignedSource<<f4a1a8e5ca8be824637c29953a9c04a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AWSOrganisationInput = {
  custodianRole?: string | null | undefined;
  externalID?: string | null | undefined;
  memberRole?: string | null | undefined;
  name?: string | null | undefined;
  orgReadRole: string;
};
export type AddAWSOrganisationMutation$variables = {
  input: AWSOrganisationInput;
};
export type AddAWSOrganisationMutation$data = {
  readonly addAWSOrganisation: {
    readonly organisation: {
      readonly custodianRole: string;
      readonly externalID: string | null | undefined;
      readonly memberRole: string;
      readonly name: string;
      readonly orgID: string;
      readonly orgRole: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddAWSOrganisationMutation = {
  response: AddAWSOrganisationMutation$data;
  variables: AddAWSOrganisationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AWSOrganisationPayload",
    "kind": "LinkedField",
    "name": "addAWSOrganisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSOrganisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orgID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orgRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodianRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memberRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAWSOrganisationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAWSOrganisationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d19d5cf1dafb66aa19505db6e247fa7e",
    "id": null,
    "metadata": {},
    "name": "AddAWSOrganisationMutation",
    "operationKind": "mutation",
    "text": "mutation AddAWSOrganisationMutation(\n  $input: AWSOrganisationInput!\n) {\n  addAWSOrganisation(input: $input) {\n    organisation {\n      orgID\n      name\n      orgRole\n      custodianRole\n      memberRole\n      externalID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc00ea6038e57c83a2bed57552cdd9d4";

export default node;
