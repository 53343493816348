import { getTime } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { DATETIME_FORMAT_STR } from "app/utils/consts";

interface ComputedExecutionDetails {
  timeStampInUTC: string;
  executionTime: string;
  avgApiRate: string;
}

const NO_DATA = "--";

export function computeExecutionDetails(
  startTime?: string,
  endTime?: string | null,
  apiCalls?: number | null,
): ComputedExecutionDetails {
  let timeStampInUTC = NO_DATA;
  let executionTime = NO_DATA;
  let avgApiRate = NO_DATA;

  if (startTime && endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    timeStampInUTC = formatInTimeZone(startTime, "UTC", DATETIME_FORMAT_STR);

    const executionTimeInMilliSec = getTime(endDate) - getTime(startDate);
    const executionTimeInSec = executionTimeInMilliSec / 1000;
    executionTime = new Date(executionTimeInSec * 1000)
      .toISOString()
      .substr(11, 8);

    avgApiRate = (
      executionTimeInSec === 0 ? 0 : (apiCalls || 0) / executionTimeInSec
    ).toFixed(2);
  }
  return { timeStampInUTC, executionTime, avgApiRate };
}
