/**
 * @generated SignedSource<<77a51b75d1625df5c677e4a0194f782f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetGroupRolesInput = {
  items: ReadonlyArray<AddGroupRoleInput>;
};
export type AddGroupRoleInput = {
  group: string;
  role: string;
};
export type UpdateDefaultRoleInput = {
  role?: string | null | undefined;
};
export type UpdateGroupRolesMutation$variables = {
  connections: ReadonlyArray<string>;
  defaultRoleInput: UpdateDefaultRoleInput;
  groupRoleInput: SetGroupRolesInput;
};
export type UpdateGroupRolesMutation$data = {
  readonly setGroupRoles: {
    readonly added: ReadonlyArray<{
      readonly group: string;
      readonly id: string;
      readonly role: string;
    }>;
    readonly removed: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly updateDefaultRole: string | null | undefined;
};
export type UpdateGroupRolesMutation = {
  response: UpdateGroupRolesMutation$data;
  variables: UpdateGroupRolesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "defaultRoleInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupRoleInput"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "groupRoleInput"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GroupRole",
  "kind": "LinkedField",
  "name": "added",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "defaultRoleInput"
    }
  ],
  "kind": "ScalarField",
  "name": "updateDefaultRole",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateGroupRolesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SetGroupRolesPayload",
        "kind": "LinkedField",
        "name": "setGroupRoles",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateGroupRolesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SetGroupRolesPayload",
        "kind": "LinkedField",
        "name": "setGroupRoles",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "added",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "GroupRoleEdge"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RemovedNode",
            "kind": "LinkedField",
            "name": "removed",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "f1f93f896d1e6d93e355c31bbd0b6f11",
    "id": null,
    "metadata": {},
    "name": "UpdateGroupRolesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateGroupRolesMutation(\n  $groupRoleInput: SetGroupRolesInput!\n  $defaultRoleInput: UpdateDefaultRoleInput!\n) {\n  setGroupRoles(input: $groupRoleInput) {\n    added {\n      id\n      role\n      group\n    }\n    removed {\n      id\n    }\n  }\n  updateDefaultRole(input: $defaultRoleInput)\n}\n"
  }
};
})();

(node as any).hash = "3d5e5b82da72937e16b34d13b4ad9d50";

export default node;
