import { useMemo } from "react";

import { useAnalytics, useConfig } from "app/contexts";

import { usePageEvent } from "./usePageEvent";
import { useUserWithIdentity } from "./useUserWithIdentity";

export function useInitAnalytics(
  version: string,
  cloudCustodianVersion: string,
) {
  const analytics = useAnalytics();
  const config = useConfig();
  const user = useUserWithIdentity();

  useMemo(() => {
    analytics.identify(user.identity?.userId || user.username);
    const customer = config?.customer ?? "Stacklet";
    analytics.group(customer, { name: customer });
  }, [analytics, user, config?.customer]);

  usePageEvent(version, cloudCustodianVersion);
}
