import { Suspense, useMemo } from "react";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { graphql } from "react-relay";
import { usePaginationFragment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";

import NoData from "app/components/empty-state/NoData";
import { HumanTimestamp } from "app/components/HumanTimestamp";
import { TableLink } from "app/components/links";
import { RectangleSkeleton } from "app/components/skeletons";
import Table from "app/components/table/XGridTable";
import TableActionMenu from "app/components/TableActionMenu";
import { useHasRequiredPermission } from "app/hooks";
import { useLoadNext } from "app/hooks";
import { ADMIN_PERMISSIONS } from "app/utils/consts";

import type { PolicyCollectionBindings_bindings$key } from "./__generated__/PolicyCollectionBindings_bindings.graphql";
import type { PolicyCollectionBindingsRefetchQuery } from "./__generated__/PolicyCollectionBindingsRefetchQuery.graphql";
import type { GridColDef } from "@mui/x-data-grid-pro";

interface Props {
  policyCollection: PolicyCollectionBindings_bindings$key;
}

export function PolicyCollectionBindings({ policyCollection }: Props) {
  const hasPermission = useHasRequiredPermission();
  const navigate = useNavigate();

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    PolicyCollectionBindingsRefetchQuery,
    PolicyCollectionBindings_bindings$key
  >(
    graphql`
      fragment PolicyCollectionBindings_bindings on PolicyCollection
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" })
      @refetchable(queryName: "PolicyCollectionBindingsRefetchQuery") {
        bindings(first: $first, after: $after)
          @connection(key: "PolicyCollectionBindings_bindings") {
          edges {
            node {
              id
              name
              accountGroup {
                name
              }
              policyCollection {
                name
              }
              lastDeployed
              runs {
                edges {
                  node {
                    startTime
                  }
                }
              }
              uuid
            }
          }
          pageInfo {
            total
          }
        }
      }
    `,
    policyCollection,
  );

  const loadMore = useLoadNext(hasNext, loadNext);

  const rows = (data.bindings.edges ?? [])
    .filter((edge) => edge.node)
    .map((binding) => {
      const { node } = binding;
      return {
        accountGroup: node.accountGroup,
        id: node.id,
        lastDeployed: node.lastDeployed,
        startTime: node?.runs?.edges.length
          ? node.runs.edges[0].node.startTime
          : null,
        name: node.name,
        uuid: node.uuid,
      };
    });

  type BindingRow = (typeof rows)[0];

  const columns: GridColDef<BindingRow>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        renderCell: (params) => {
          const { name, uuid } = params.row;
          const url = hasPermission(ADMIN_PERMISSIONS)
            ? `/admin/bindings/${uuid}`
            : `/bindings/${uuid}`;
          return <TableLink name={name} to={url} />;
        },
      },
      {
        headerName: "Account Group",
        field: "accountGroupName",
        valueGetter: (params) => params.row.accountGroup.name,
      },
      {
        headerName: "Last Deployed",
        field: "lastDeployed",
        renderCell: (params) => <HumanTimestamp timestamp={params.value} />,
      },
      {
        headerName: "Last Run",
        field: "startTime",
        renderCell: (params) => <HumanTimestamp timestamp={params.value} />,
      },
      {
        headerName: "Actions",
        field: "menuActions",
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const { uuid } = params.row;
          return (
            <TableActionMenu>
              <MenuItem
                aria-label="edit binding"
                onClick={() => navigate(`/admin/bindings/${uuid}/edit`)}
              >
                edit
              </MenuItem>
            </TableActionMenu>
          );
        },
      },
    ],
    [hasPermission, navigate],
  );

  const rowCount = data.bindings.pageInfo.total || rows.length;

  return (
    <Suspense fallback={<RectangleSkeleton height="800px" />}>
      {rowCount === 0 ? (
        <Box mt={3}>
          <NoData message="No bindings found" />
        </Box>
      ) : (
        <Table
          columns={columns}
          hasToolbar={false}
          loading={isLoadingNext}
          onRowsScrollEnd={loadMore}
          rowCount={rowCount}
          rows={rows}
          tableId="overview-policies-details-bindings"
        />
      )}
    </Suspense>
  );
}
