import { graphql } from "react-relay";

export const RemoveAccountGroupMutation = graphql`
  mutation RemoveAccountGroupMutation($uuid: String!) {
    removeAccountGroup(uuid: $uuid) {
      group {
        id @deleteRecord
      }
    }
  }
`;
