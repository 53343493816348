import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { Tab } from "app/components/Tab";
import { useStickyState } from "app/hooks";
import { Dashboard } from "app/views/dashboards/components";
import { Timeline } from "app/views/dashboards/components/Timeline";
import OverviewPage from "app/views/overview/OverviewPage";

import { OverallCompliance } from "./cards/compliance/OverallCompliance";
import { PerSection } from "./cards/compliance/PerSection";
import { OverallAnnualCost } from "./cards/cost/OverallAnnualCost";
import { OverallFindings } from "./cards/findings/OverallFindings";
import { DashboardDescription } from "./DashboardDescription";
import { PolicyTable } from "./policy-table/PolicyTable";

import type { ComplianceDashboardQuery } from "./__generated__/ComplianceDashboardQuery.graphql";

export const DashboardWrapper = styled("section")(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const StyledTabPanel = styled(TabPanel)(() => ({
  paddingLeft: 0,
  paddingTop: 0,
}));

export default function ComplianceDashboard() {
  const [tab, setTab] = useStickyState("1", "dashboard-tabs");

  const params = useParams();
  const key = params.key || "";

  const data = useLazyLoadQuery<ComplianceDashboardQuery>(
    graphql`
      query ComplianceDashboardQuery($key: String!) {
        dashboard(key: $key) {
          title
          ...OverallAnnualCost_dashboard
          ...DashboardDescription_dashboard
          ...OverallFindings_dashboard
          ...OverallCompliance_dashboard
          ...PolicyTable_dashboard
          ...Timeline_dashboard
          ...PerSection_dashboard
        }
      }
    `,
    {
      key,
    },
  );

  if (!data) {
    return null;
  }

  if (!data.dashboard) {
    return (
      <OverviewPage title="Dashboard not found">
        <DashboardWrapper>
          <span>{`Dashboard ${key} not found. Please select a dashboard from the menu.`}</span>
        </DashboardWrapper>
      </OverviewPage>
    );
  }

  const { title } = data.dashboard;

  const isCostDashboard = key.includes("cost");

  return (
    <OverviewPage title={title}>
      <Dashboard title={title}>
        <DashboardDescription dashboard={data.dashboard} />
        <TabContext value={tab}>
          <TabList
            aria-label="dashboard tabs"
            onChange={(e: React.SyntheticEvent, newValue: string) =>
              setTab(newValue)
            }
          >
            <Tab label="Recommendations" value="1" />
            <Tab label="Stats" value="2" />
          </TabList>

          <StyledTabPanel value="1">
            <DashboardWrapper>
              <PolicyTable dashboard={data.dashboard} key={key} />
            </DashboardWrapper>
          </StyledTabPanel>

          <StyledTabPanel value="2">
            <DashboardWrapper>
              <Grid
                direction="row"
                justifyContent="flex-start"
                spacing={4}
                container
              >
                {!isCostDashboard ? (
                  <Grid xs={6} item>
                    <OverallCompliance dashboard={data.dashboard} />
                  </Grid>
                ) : null}

                <Grid xs={6} item>
                  <OverallFindings dashboard={data.dashboard} />
                </Grid>

                {isCostDashboard ? (
                  <Grid xs={6} item>
                    <OverallAnnualCost dashboard={data.dashboard} />
                  </Grid>
                ) : null}
              </Grid>
            </DashboardWrapper>

            <Divider sx={{ my: 4 }} />

            <DashboardWrapper>
              <Timeline dashboard={data.dashboard} />
            </DashboardWrapper>

            {!isCostDashboard ? (
              <>
                <Divider sx={{ my: 4 }} />
                <DashboardWrapper>
                  <PerSection dashboard={data.dashboard} />
                </DashboardWrapper>
              </>
            ) : null}
          </StyledTabPanel>
        </TabContext>
      </Dashboard>
    </OverviewPage>
  );
}
