import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAnalytics } from "app/contexts";

export function usePageEvent(
  version: string,
  cloudCustodianVersion: string,
): void {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    if (version && cloudCustodianVersion) {
      analytics.page(
        {},
        {
          app: {
            version: `Stacklet ${version} (ui: ${
              import.meta.env.VITE_GIT_HASH
            }, c7n: ${cloudCustodianVersion})`,
            name: "stacklet-console",
          },
        },
      );
    }
  }, [location, analytics, version, cloudCustodianVersion]);
}
