import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import HelpButton from "./HelpButton";
import { SearchBar } from "./SearchBar";

const SEARCHBAR_MAXWIDTH = 2500;

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(30),
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  "&+.gridItem": {
    marginLeft: theme.spacing(5),
  },
}));

const LeftBarStyledGrid = styled(StyledGrid)(({ theme }) => ({
  height: theme.spacing(4),
  flexGrow: 1,
  alignItems: "center",
}));

interface Props {
  help?: string[];
  title?: string;
  hideSearchBar?: boolean;
  children?: React.ReactNode;
  onSearch?: (search: string) => void;
}

export default function ToolbarHeader({
  help,
  title = "",
  hideSearchBar = false,
  children,
  onSearch,
}: Props): JSX.Element {
  return (
    <Root>
      <Grid alignItems="center" container>
        <LeftBarStyledGrid item>
          <Box
            alignItems="center"
            display="flex"
            sx={{ maxWidth: SEARCHBAR_MAXWIDTH }}
          >
            {!hideSearchBar && (
              <Box flexGrow={1} mr={1}>
                <SearchBar onSearch={onSearch} title={title} />
              </Box>
            )}
            {help ? (
              <Box>
                <HelpButton pageNames={help} />
              </Box>
            ) : null}
          </Box>
        </LeftBarStyledGrid>
        {children ? (
          <StyledGrid className={"gridItem"} item>
            {children}
          </StyledGrid>
        ) : null}
      </Grid>
    </Root>
  );
}
