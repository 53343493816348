/**
 * @generated SignedSource<<2ddab32c286d0ec7f8d49333e86a0e24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type useEnabledDashboardsQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
};
export type useEnabledDashboardsQuery$data = {
  readonly dashboards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly key: string;
        readonly title: string;
      };
    }>;
  };
};
export type useEnabledDashboardsQuery = {
  response: useEnabledDashboardsQuery$data;
  variables: useEnabledDashboardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEnabledDashboardsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DashboardConnection",
        "kind": "LinkedField",
        "name": "dashboards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dashboard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEnabledDashboardsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DashboardConnection",
        "kind": "LinkedField",
        "name": "dashboards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dashboard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9acc2f1920923a867b59dbb467360cf1",
    "id": null,
    "metadata": {},
    "name": "useEnabledDashboardsQuery",
    "operationKind": "query",
    "text": "query useEnabledDashboardsQuery(\n  $filterElement: FilterElementInput\n) {\n  dashboards(filterElement: $filterElement) {\n    edges {\n      node {\n        key\n        title\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3361c9e6a97f00c05688c17c6e932c5e";

export default node;
