/**
 * @generated SignedSource<<3565f5f0215f056460c1a31d5c70491a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppVersion_version$data = {
  readonly cloudCustodianVersion: string;
  readonly version: string;
  readonly " $fragmentType": "AppVersion_version";
};
export type AppVersion_version$key = {
  readonly " $data"?: AppVersion_version$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppVersion_version">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppVersion_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cloudCustodianVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "type": "Platform",
  "abstractKey": null
};

(node as any).hash = "d823fc5ee863350c523c8986767b8bd1";

export default node;
