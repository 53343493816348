import { useEffect, useState } from "react";

import * as Sentry from "@sentry/browser";
import { Button } from "@stacklet/ui";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import { DeleteConfirmationDialog } from "app/components/dialogs";
import { useAlertContext } from "app/contexts/AlertContext";
import { RemovePolicyCollectionMutation } from "app/mutations";

import type { RemovePolicyCollectionMutation as RemovePolicyCollectionMutationType } from "app/mutations/__generated__/RemovePolicyCollectionMutation.graphql";

interface Props {
  policyCollectionUuid: string;
}

export default function RemovePolicyCollection({
  policyCollectionUuid,
}: Props) {
  const { alertDispatch } = useAlertContext();
  const navigate = useNavigate();

  const [isDeleteConfirmDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);
  const [deleteUuid, setDeleteUuid] = useState<string>("");

  useEffect(() => {
    setDeleteUuid(policyCollectionUuid);
  }, [policyCollectionUuid]);

  const [removePolicyCollection] =
    useMutation<RemovePolicyCollectionMutationType>(
      RemovePolicyCollectionMutation,
    );

  const openDialog = (value: boolean) => {
    setIsConfirmDeleteDialogOpen(value);
  };

  const confirmDelete = () => {
    deletePolicyCollection(deleteUuid);
  };

  const onDeleteFailure = (errorMessage: string) => {
    alertDispatch({
      message: `failed to remove policy collection: ${errorMessage}`,
      severity: "error",
    });
  };

  const deletePolicyCollection = (uuid: string) => {
    removePolicyCollection({
      variables: {
        uuid,
      },
      onCompleted(response, errors) {
        if (errors) {
          errors.forEach((error) => {
            onDeleteFailure(error.message);
          });
        } else {
          alertDispatch({
            message: "successfully removed policy collection",
            severity: "success",
          });
        }
        setDeleteUuid("");
        setIsConfirmDeleteDialogOpen(false);
        navigate("/admin/policy-collections");
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };

  return (
    <>
      <Button
        buttonType="destructive"
        onClick={() => {
          setIsConfirmDeleteDialogOpen(true);
          setDeleteUuid(policyCollectionUuid);
        }}
      >
        Remove
      </Button>
      <DeleteConfirmationDialog
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDialog}
        title="Policy Collection"
      />
    </>
  );
}
