/**
 * @generated SignedSource<<b669cbb73ec7fd0dc4564c9f659e96cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type PreferencesDialogQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
};
export type PreferencesDialogQuery$data = {
  readonly userData: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly value: string;
      };
    }>;
  };
};
export type PreferencesDialogQuery = {
  response: PreferencesDialogQuery$data;
  variables: PreferencesDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreferencesDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserDataConnection",
        "kind": "LinkedField",
        "name": "userData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDataEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserData",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreferencesDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserDataConnection",
        "kind": "LinkedField",
        "name": "userData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDataEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserData",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2226e62322bad8f6c5f7971158343bb7",
    "id": null,
    "metadata": {},
    "name": "PreferencesDialogQuery",
    "operationKind": "query",
    "text": "query PreferencesDialogQuery(\n  $filterElement: FilterElementInput\n) {\n  userData(filterElement: $filterElement) {\n    edges {\n      node {\n        value\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4eb2c09d660aed9ad2e9312b0ec43286";

export default node;
