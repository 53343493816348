import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { COLORS } from "@stacklet/ui";

import { Link } from "app/components";

import DetailGridValue from "./DetailGridValue";
import ExpandableDropdown from "./ExpandableDropdown";

import type { GridProps } from "@mui/material/Grid";

interface Props extends GridProps {
  title: string;
  value?: string | number | boolean | JSX.Element | null;
  withBg?: boolean;
  link?: string;
  direction?: "row" | "column";
  showResourceIcon?: boolean;
  expandable?: boolean;
}

export default function DetailsGridItem({
  title,
  value,
  children,
  withBg,
  link,
  direction = "row",
  showResourceIcon,
  expandable,
  xs,
}: Props): JSX.Element {
  const mainContent = (
    <Box
      data-testid={`detail-grid-${title.split(" ").join("-").toLowerCase()}`}
    >
      {expandable ? (
        <ExpandableDropdown title={title}>{children}</ExpandableDropdown>
      ) : (
        <DetailGridValue
          direction={direction}
          showResourceIcon={showResourceIcon}
          title={title}
          value={value}
          withBg={withBg}
        >
          {children}
        </DetailGridValue>
      )}
    </Box>
  );

  return link ? (
    <Grid xs={xs} item>
      <Link href={link} textColor={COLORS.cobalt.D30}>
        {mainContent}
      </Link>
    </Grid>
  ) : (
    <Grid xs={xs} item>
      {mainContent}
    </Grid>
  );
}
