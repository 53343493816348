/**
 * @generated SignedSource<<db9fe7eeacbe98debb02547df0e54ac9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicyResourcesFilter_filterSchema$data = {
  readonly filterSchema: {
    readonly " $fragmentSpreads": FragmentRefs<"FilterBar_filters">;
  };
  readonly " $fragmentType": "PolicyResourcesFilter_filterSchema";
};
export type PolicyResourcesFilter_filterSchema$key = {
  readonly " $data"?: PolicyResourcesFilter_filterSchema$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyResourcesFilter_filterSchema">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PolicyResourcesFilter_filterSchema",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FilterSchema",
      "kind": "LinkedField",
      "name": "filterSchema",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FilterBar_filters"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ResourceConnection",
  "abstractKey": null
};

(node as any).hash = "3d881e4b8632db1d9ed0100ed376e7e8";

export default node;
