/**
 * @generated SignedSource<<1b34f46a22be0c947fb00c478fc5e147>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type AddBindingInput = {
  accountGroupUUID: string;
  autoDeploy?: boolean | null | undefined;
  deploy?: boolean | null | undefined;
  description?: string | null | undefined;
  executionConfig?: ExecutionConfigInput | null | undefined;
  name: string;
  policyCollectionUUID: string;
  schedule?: string | null | undefined;
  variables?: string | null | undefined;
};
export type ExecutionConfigInput = {
  dryRun?: ExecutionDryRunInput | null | undefined;
  resourceLimits?: ExecutionResourceLimitsInput | null | undefined;
  securityContext?: ExecutionSecurityContextInput | null | undefined;
  variables?: string | null | undefined;
};
export type ExecutionSecurityContextInput = {
  default?: string | null | undefined;
};
export type ExecutionDryRunInput = {
  default?: boolean | null | undefined;
};
export type ExecutionResourceLimitsInput = {
  default?: ResourceLimitInput | null | undefined;
  policyOverrides?: ReadonlyArray<PolicyResourceLimitInput> | null | undefined;
};
export type ResourceLimitInput = {
  maxCount?: number | null | undefined;
  maxPercentage?: number | null | undefined;
  requiresBoth?: boolean | null | undefined;
};
export type PolicyResourceLimitInput = {
  limit: ResourceLimitInput;
  policyName: string;
};
export type AddBindingMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddBindingInput;
};
export type AddBindingMutation$data = {
  readonly addBinding: {
    readonly binding: {
      readonly accountGroup: {
        readonly name: string;
        readonly provider: CloudProvider;
        readonly uuid: string;
      };
      readonly autoDeploy: boolean;
      readonly description: string | null | undefined;
      readonly executionConfig: {
        readonly dryRun: {
          readonly default: boolean;
        } | null | undefined;
        readonly variables: string | null | undefined;
      };
      readonly id: string;
      readonly lastDeployed: string | null | undefined;
      readonly name: string;
      readonly policyCollection: {
        readonly name: string;
        readonly provider: CloudProvider | null | undefined;
        readonly uuid: string;
      };
      readonly schedule: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddBindingMutation = {
  response: AddBindingMutation$data;
  variables: AddBindingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoDeploy",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastDeployed",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedule",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ExecutionConfig",
  "kind": "LinkedField",
  "name": "executionConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExecutionDryRun",
      "kind": "LinkedField",
      "name": "dryRun",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variables",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "addBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroup",
                "kind": "LinkedField",
                "name": "accountGroup",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollection",
                "kind": "LinkedField",
                "name": "policyCollection",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "addBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroup",
                "kind": "LinkedField",
                "name": "accountGroup",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PolicyCollection",
                "kind": "LinkedField",
                "name": "policyCollection",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "binding",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "BindingEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf9b7f1557f5ad9f61f043900094cb1c",
    "id": null,
    "metadata": {},
    "name": "AddBindingMutation",
    "operationKind": "mutation",
    "text": "mutation AddBindingMutation(\n  $input: AddBindingInput!\n) {\n  addBinding(input: $input) {\n    binding {\n      id\n      uuid\n      name\n      description\n      autoDeploy\n      lastDeployed\n      accountGroup {\n        name\n        uuid\n        provider\n        id\n      }\n      policyCollection {\n        name\n        uuid\n        provider\n        id\n      }\n      schedule\n      executionConfig {\n        dryRun {\n          default\n        }\n        variables\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ba530161568cf3eaf6a33a295e4d700";

export default node;
